<template>
    <div class="page-footer uf uf-ac uf-pc">
        <div class="uf uf-ver uf-pc uf-ac box1">
            <div class="uf uf-ac">
                <img src="../assets/img/logo-sp-white.png" width="35">
                <div class="f18 ml-3">服务型制造共性技术公共服务平台</div>
            </div>
            <div class="mt-2 op-05 f12">
                © 2023-2025 版权所有 服务型制造共性技术公共服务平台
                <a style="color:#FFF; text-decoration:none" href="https://beian.miit.gov.cn/" target="_blank">
                    鲁ICP备2023017334号-1</a>
            </div>
        </div>
        <div class="left-box">
            <div class="f12 op-07 mb-3">友情链接：</div>
            <span class="uf uf-ac cp">
              <div class=" uf uf-pj uf-ac "  @click="toHref('https://design.somfgs.cn/')">
                <img src="../assets/img/index/logo-yuanfang.png" style="height: 15px"/>
                <div class="c-white ml-1 f12">Meta20设计平台</div>
              </div>
              <div class=" uf uf-pj uf-ac "  @click="toHref('https://oms.somfgs.cn/')">
                <img src="https://design.somfgs.cn/Meta20.ico" style="height: 15px ;margin-left: 15px"/>
                <div class="c-white ml-1 f12">OMS20订单平台</div>
              </div>
              <div class=" uf uf-pj uf-ac "  @click="toHref('https://fdm.somfgs.cn/')">
                <img src="https://fdm.somfgs.cn/assets/logo-mini.1724227505041.svg" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">IMS20智能制造平台</div>
              </div>
              <div class=" uf uf-pj uf-ac "  @click="toHref('https://gin.somfgs.cn/#/ ')">
                <img src="https://frypan.somfgs.cn/favicon.ico" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">恒温智能控制平台</div>
              </div>
              <div class=" uf uf-pj uf-ac "  @click="toHref('https://frypan.somfgs.cn/')">
                <img src="https://frypan.somfgs.cn/favicon.ico" style="height: 15px ;margin-left: 15px"/>
                <div class="c-white ml-1 f12">食材分析平台</div>
              </div>
<!--              <div class=" uf uf-pj uf-ac "  @click="toHref('https://evaluation.somfgs.cn/')">-->
<!--                <img src="https://www.somfgs.cn/favicon.ico" style="height: 15px;margin-left: 15px"/>-->
<!--                <div class="c-white ml-1 f12">制造服务数据价值评估平台</div>-->
<!--              </div>-->
               <div class=" uf uf-pj uf-ac "  @click="toHref('http://139.196.201.183:4050/')">
                <img src="../assets/img/logozc.png" style="height: 15px"/>
                <div class="c-white ml-1 f12">轴承保持器行业标准化服务平台</div>
              </div>
<!--              <div class=" uf uf-pj uf-ac "  @click="toHref('http://139.196.201.183:4000/')">-->
<!--                <img src="../assets/img/logo-xy.png" style="height: 15px ;margin-left: 15px"/>-->
<!--                <div class="c-white ml-1 f12">企业融资信用运维平台</div>-->
<!--              </div>-->

            </span>
            <span class="uf uf-ac cp" style="margin-top: 15px">
<!--              <div class=" uf uf-pj uf-ac "  @click="toHref('http://139.196.201.183:4010/')">-->
<!--                <img src="../assets/img/logozr.png" style="height: 15px;margin-left: 15px"/>-->
<!--                <div class="c-white ml-1 f12">政企融合平台</div>-->
<!--              </div>-->
              <div class=" uf uf-pj uf-ac "  @click="toHref('http://139.196.201.183:4020/')">
                <img src="../assets/img/logocn.png" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">产能共享平台</div>
              </div>
               <div class=" uf uf-pj uf-ac "  @click="toHref('http://139.196.201.183:4030/')">
                <img src="../assets/img/logocg.png" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">采购协同平台</div>
              </div>
              <div class=" uf uf-pj uf-ac "  @click="toHref('https://indatax.somfgs.cn/')">
                <img src="../assets/img/logoin.png" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">数据中台</div>
              </div>
<!--              <div class=" uf uf-pj uf-ac "  @click="toHref('https://www.indintel.net/')">-->
<!--                <img src="../assets/img/szzz.png" style="height: 15px;margin-left: 15px"/>-->
<!--                <div class="c-white ml-1 f12">数字制造</div>-->
<!--              </div>-->
<!--               <div class=" uf uf-pj uf-ac "  @click="toHref('https://lbxx.somfgs.cn')">-->
<!--                <img src="" style="height: 15px;margin-left: 15px"/>-->
<!--                <div class="c-white ml-1 f12">联邦学习</div>-->
<!--              </div>-->
              <div class=" uf uf-pj uf-ac "  @click="toHref('http://117.73.11.84:91/index#/login?redirect=%2Findex')">
                <img src="" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">装备智能运维平台</div>
              </div>
               <div class=" uf uf-pj uf-ac "  @click="toHref('https://share.somfgs.cn')">
                <img src="../assets/img/share.png" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">共享平台-微星网</div>
              </div>
               <div class=" uf uf-pj uf-ac "  @click="toHref('https://code.somfgs.cn')">
                <img src="../assets/img/share.png" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">模塑ERP管理系统</div>
              </div>
               <div class=" uf uf-pj uf-ac "  @click="toHref('https://data.pharmacodia.com/')">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAbCAYAAABiFp9rAAAD6klEQVRIS62WS2xUZRTHf+fOTAsMDyUaRSPgI3GjxkctM2MhlkSLBTfgg6QuDAs2prZNZ9qm1jhiqmFmGgO4ERJ0YZHYQFgQcIMQ23lUiBqNxviIRl0QFlYotQPM3GPug5nbdqbU1m81c757zv+c8/3PQ5jL6UyvwZA2MJpB70bJY8jXKIP8tvRDhh64eiMzMusHrbnl1GovQhuwqOK3ys+I9JAMHZnNVjUgIZbZjkoc0VvLBqQGWAwYM4wqIxhGB4l15yoBzh5RJY34aT/jtfchsh50O0gjwnU7JvAxRqGX3et/96qXgWLpezEDKxmoP3ujfE+578w9huhehEhJrkwCCS4F+tlfd82SO0BduRCqJ1DpJBX6wJZFRzeC+bijrCboFWAcjF/B/J7UExdKhuNqMJHtB+mZ6qR8RsHcwruRSaFn+GYKge8QXYXKjjJQ9k+EO6sQQBG+AElOIUE08zoiu6boKAdIhXcK0XQcMd5wHPcAxbLngdtQLKN/gBZQiwhyu+dNQOQwk3+9zL5mK2KI5oYQfc6TxiJi3i/Est8AD84CFCcVfrOk2JFZiU+2IfQCa93UHiMZ3gqitH++ikDgJyBY0jHNqEXjPEhtdSBtIRU5NCOFXSPLUP9h0GZHV1tJRd6zf8eye4HWso6+b0Wk5TArpc4DFMvssLvCpZohm00dmcX4ZdTNyHmC+buINxboytSjttw9cvA/AmX/BlYApwiGniYuJtHRzYh53LYospFE6DQWCy/nLiIsdZDmDwQUN5NsOIFVwBOLLAeCiERJhAbc9JXffkFAShupsPUWEMv8AnKPXaTJcLfDvuwwQsP/EJHxLMl1x4nHDS43jSEsB14jGX7bAcp8hcjDCwVKczb/JGcaC3YHEfOUS/NnSEY+tcEnmqx0LpsnUOYVkCsE8x8Rb8yz81yAFVdHQOqBC+THVtuF2zVSh/o8PdMmwzzryAa5dhB4yfW6nWRoj/s+exBeLbObA3PoDNMK9vlPfKy9owl8bwGPOuzlJEtCW2y6W53BH/ixTG2rmOmbQ6+bUrBHUZ5CxK0PuyUc42JNC/vr/nGjOYKwtVysKMXCI9W7d6mpeoFyY6A32UaUH0D6SYUG7X82zdN9YFiRes8hkuGW6vOoElA0+4Ldwf36Jbsj35asORTf5Tba8jBVMhjFTSQaxqtP2EpA01x1UpV7CMx9iGwoXVsT1iDB5Ng718dH9Z2hKpAK7WfWEKjdAPIisMmzrJioDuIr9lXfGaZ7G0tvA2MA5RZEPXubLAGcseI9C9qC7DFg9KAaQ+w1a+ax9jqlm4Hw0Yr3rnBu61b38GpMX/tCNtV/ARoUC/zXZHKuAAAAAElFTkSuQmCC" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">药渡数据</div>
              </div>
               <div class=" uf uf-pj uf-ac "  @click="toHref('https://www.sqtxj.com/pc/index.html')">
                <img src="https://www.sqtxj.com/pc/skin/images/title.ico" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">陕汽天行健</div>
              </div>
               <div class=" uf uf-pj uf-ac "  @click="toHref('http://www.zheliyin.com/')">
                <img src="../assets/img/index/sjky.png" style="height: 15px;margin-left: 15px"/>
                <div class="c-white ml-1 f12">世纪开元</div>
              </div>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'page-footer',
    data() {
        return {
            // symbole: <script src='https://dcs.conac.cn/js/16/000/0000/61055564/CA160000000610555640001.js' type='text/javascript'/>
        }
    },
    methods: {
        toHref(url){
            window.open(url, '_blank')
        }
    }
}
</script>

<style scoped>
.page-footer {
    background: #000000;
    padding: 40px 0;
    color: white;
}
.box1 {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #868686;
}
</style>
