<template>
    <div @click="showDetail">
        <div class="pic-box uf uf-ac">
            <el-image :src="$cons.SERVER_PATH + service.itemUrl"
                      fit="fill" class="uf-f1" style="height: 140px;border-radius: 10px;overflow: hidden">
                <div slot="error">
                    <div class="item-error">
                        <div>
                            {{
                            service.serviceName && service.serviceName.length > 16 ? service.serviceName.substr(0, 15) + '...' : service.serviceName
                            }}
                        </div>
                    </div>
                </div>
            </el-image>
            <div class="service-desc-box" style="width: 60%">
                <div class="ctx-link fwb">
                    <el-tooltip :content="service.serviceName" effect="light">
                        <div class="service-name-wrapper">{{ service.serviceName }}</div>
                    </el-tooltip>
                </div>
                <div class="price-box mt-1">
          <span v-if="service.minPrice !== service.maxPrice && service.minPrice !== -1"><span
                  class="f20">￥</span>{{ (service.minPrice || 0).toFixed(2) }} - {{
              (service.maxPrice || 0).toFixed(2)
              }}</span>
                    <span class="f18" v-else-if="service.minPrice === service.maxPrice && service.minPrice !== -1">
                      ￥{{ (service.minPrice || 0).toFixed(2) }}
                    </span>
                    <span v-else class="f18">面议</span>
                </div>
                <div class="mt-1">
                    <div class="f12">
                        <span class="op-07">服务分类：{{ service.itemName }}</span>
                        <span></span>
                    </div>
                </div>
                <div class="mt-1">
                    <div class="f12">
                        <span class="op-07" :title="service.enterpriseName">服务商：{{
                            ellipsis(service.enterpriseName)
                            }}</span>
                        <span></span>
                    </div>
                </div>
                <div class="mt-1">
                    <div class="f12">
                        <span class="op-07">服务标识：{{serviceMethodDictMap[service.serviceLabel]}}</span>
                        <span></span>
                    </div>
                </div>
                <!--        <div class="extra-box">-->
                <!--          <div style="padding: 0 7px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">-->
                <!--            自营-->
                <!--          </div>-->
                <!--          <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">-->
                <!--            严选-->
                <!--          </div>-->
                <!--          <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">-->
                <!--            服务质保-->
                <!--          </div>-->
                <!--        </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'service-card',
    props: {
        service: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
          serviceMethodDictMap: {ORDER: "订单", RECORD: "方案服务", ASK: "咨询服务", COMMON: "一般服务", API: "组件服务"},
          needDictMap: {ywc: "已完成", ygb: "已关闭", dgj: "待跟进", ycd: "已成单", dyhqr: "待用户确认"}
        }
    },
    created() {
        // this.$getDictMap('SERVICE_LABEL,NEED_STATUS', dict => {
        //     this.serviceMethodDictMap = dict['SERVICE_LABEL']
        //     this.needDictMap = dict['NEED_STATUS']
        // })
    },
    methods: {
        showDetail() {
            this.$emit('showDetail')
        },
        //控制字数
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 12) {
                return value.slice(0, 12) + '...'
            }
            return value
        }
    }
}
</script>

<style scoped>
.price-box {
    font-size: 1.2rem;
    height: 1.5rem;
    line-height: 1.5rem;
    color: #fe3b25;
}

.pic-box {
    padding: 15px;
    width: 430px;
    height: auto;
    margin-bottom: 15px;
    background-color: #FFFFFF;
    border-radius: 13px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgb(0 0 0 / 8%);
}

.pic-box:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, .15);
    transform: translate3d(0, -2px, 0);
    cursor: pointer;
}

.item-error {
    /*width: 245px;*/
    height: 140px;
    background-image: url("../../assets/img/product/default_3.png");
    background-size: cover;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2rem;
    padding: 40px 20px;
    color: #FFFFFF;
    overflow: hidden;
}

.service-desc-box {
    padding: 0px 10px 10px 10px;
}

.service-name-wrapper {
    line-height: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
}

.extra-box {
    display: -webkit-flex !important;
    display: flex !important;
    justify-content: flex-start;
    position: relative;
    top: 10px;
    left: 0px;
    font-size: 10px;
}
</style>
