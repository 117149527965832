<template>
  <div class="application">
    <div
      class="application--header"
      :style="{ backgroundImage: `url(${activeObj.bg})` }"
    >
      <div class="header__content">
        <div class="header__title">{{ activeObj.label }}</div>
        <div class="header__desc line-clamp">{{ activeObj.sence }}</div>
      </div>
      <div class="header__nav">
        <NavBar v-model="activeValue" :menuItems="menuItems" />
      </div>
    </div>
    <div class="application__content">
      <div>
        <el-carousel
          indicator-position="outside"
          :autoplay="false"
          height="80vh"
          class="wi100"
        >
          <el-carousel-item
            v-for="(projectList, i) in projectsBannerList"
            :key="i"
            class="hi100"
          >
            <div class="project-container">
              <div
                v-for="(project, index) in projectList"
                :key="project.name"
                class="project-item"
                @click.stop="handleProjectClick(project.url)"
              >
                <!--        <div-->
                <!--            v-for="(project, index) in activeProjects"-->
                <!--            :key="project.name"-->
                <!--            class="project-item"-->
                <!--            :style="{-->
                <!--              '&#45;&#45;bg-img': `url(${project.img})`-->
                <!--            }"-->
                <!--        >-->
                <div class="project-item--container">
                  <div class="project-item--tips"></div>
                  <div class="project-item--label">{{ project.name }}</div>
                  <div class="project-item--line"></div>
                  <div class="project-item--desc">{{ project.desc }}</div>
                  <div class="project-item--count">
                    <div class="current">{{ index + 1 + i * 3 }}</div>
                    <div class="separator">/</div>
                    <div class="total">{{ activeProjects.length }}</div>
                  </div>
                </div>
                <div class="footer">
                  <div>了解详情</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/views/application/modules/NavBar.vue'
export default {
  components: { NavBar },
  data() {
    return {
      activeValue: 'biomedical',
      menuItems: [
        {
          label: '生物医药行业',
          value: 'biomedical',
          bg: require('@/assets/img/trades/shengWuYiYao.jpg'),
          icon: 'icon-shengwuyiyao',
          sence:
            '基于超算的药物筛选和研发。提供软件和算力支持的方式加快药物筛选、基因组学检测分析等进程。让以往需以月乃至年为计的运算、检测时间被缩短到几天，大大缩短药物研制时间，快速进入临床治疗。服务平台可基于超算中心的算力支撑，对接药物研发企业的研发能力和药物生产企业的生产能力。',
          tech: '基于超算的数字化服务；面向制造服务生态体系的数据交换方法',
          projects: [
            {
              name: '药渡数据',
              url: 'https://data.pharmacodia.com/',
              desc:
                '药渡集团始创于2013年,专注于生物医药领域大数据和人工智能的研究应用,是中国生物医药行业解决方案与数据情报研发机构的领跑者。\n药渡集团通过十年海量数据积累形成湖仓一体的数据管理模式,利用自然语言处理、知识图谱、数据挖掘、数据可视化等先进技术,构建强大的医药大数据聚合系统,具备深度趋势预测和机会推荐的综合分析能力。秉承“助力药物创新,加速药物研发的企业使命,成功打造“基于大数据挖掘与人工智能技术的医药研发领域专业服务平台”,数据全面覆盖全球药物、全球器械、药品销量、临床研究、投资生态、专利文献、新闻政策。依托平台优势,成功打造出本土生物医药咨询领域第一品牌“药渡咨询”,具备商业尽调、行业研究、战略规划、IPO顾问等成熟服务经验。药渡集团已先后为国内外上千家客户提供数据、资源、咨询服务,客户涵盖跨国药企、国内综合/创新药企、产业园区、政府机关、投融资机构等,赢得客户广泛好评。'
            }
          ]
        },
        {
          label: '机械装备行业',
          value: 'equipment',
          bg: require('@/assets/img/trades/jiXie.jpg'),
          icon: 'icon-jixie',
          sence:
            '提供装备的智能运维服务。基于智能传感设备和智能边缘计算网关等实现对装备的实时监测与预警，提供从边缘计算到工业云平台端到端的服务，达到装备安全长周期运行维护的目的。使机械装备生产企业实现服务型制造转型。',
          tech:
            '基于AI+5G+IoT的云边端架构设备远程运维与故障诊断；工业海量数据管理与处理技术；面向全生命周期的制造服务数据治理。',
          projects: [
            {
              name: '装备智能运维平台',
              img: require('@/assets/img/mh/zbznyw.png'),
              url: 'http://117.73.11.84:91/index#/login?redirect=%2Findex',
              desc:
                '装备智能运维平台是一种集成了物联网、大数据、人工智能等先进技术的综合性管理平台，旨在提高设备的运行效率、降低维护成本，并提升整体生产管理水平'
            },
            {
              name: '轴承保持器工业互联网行业看板',
              img: require('@/assets/img/mh/zcbcqkb.png'),
              url: 'https://zckb.somfgs.cn/',
              desc:
                '从多个维度展示设备分布、产量分布、日产趋势、生产态势、产量增速和产能饱和度。'
            },
            {
              name: '轴承保持器行业标准化服务平台',
              img: require('@/assets/img/mh/zcbcq.png'),
              url: 'http://139.196.201.183:4050/',
              desc:
                '聊城轴承保持器工业互联网平台聚焦聊城轴承保持器生产集群，提供全程数据采集、工艺、质量、仓储、运输等一站式平台化服务。提供了制造设备生产数据实时汇聚、计算；生产任务分配；生产进度采集；品质巡检、抽查、质量回溯；工人手持终端智能应用；产能分析等功能。'
            },
            {
              name: '数字制造',
              img: require('@/assets/img/mh/znzz.png'),
              url: 'https://www.indintel.net/',
              desc:
                '基于现代信息技术的智能化制造系统，它以产品制造数据为基础，通过信息化手段对整个生产过程进行仿真、管控和优化，并拓展到产品整个生命周期。'
            },
            {
              name: '产能共享平台',
              img: require('@/assets/img/mh/cgjg.png'),
              url: 'http://139.196.201.183:4020/',
              desc:
                '共享生产资源和能力，提高资源利用效率，促进产业升级和经济发展'
            },
            {
              name: '采购协同平台',
              img: require('@/assets/img/mh/cgpt.png'),
              url: 'http://139.196.201.183:4030/',
              desc:
                '通过协同合作的方式，实现各企业之间采购信息和资源的共享，以降低采购成本、提高采购效率，并共同应对市场变化。'
            }
          ]
        },
        {
          label: '汽车行业',
          value: 'car',
          bg: require('@/assets/img/trades/qiChe.jpg'),
          icon: 'icon-qiche',
          sence:
            '商用车全生命周期服务平台。例如陕西汽车“车轮滚滚”O2O服务平台，以整车产品为基础，在持续提升基本售后服务的同时，开展融资租赁、经营性租赁、商业保险、保险经纪、车联网数据服务等一系列增值服务业务。',
          tech:
            '产品服务系统设计与优化技术；制造服务智能交易技术；面向制造服务生态体系的数据交换方法。',
          projects: [
            {
              name: '货达网络',
              url: 'https://desk-frontend.yunxiaobao.com',
              desc:
                '国内领先的大宗商品物流及供应链互联网集团企业，全国首批5A级网络货运企业。公司专注大宗能化产业，致力于提供覆盖大宗商品贸易、物流、车后及金融全流程的数字供应链服务。'
            },
            {
              name: '卡一车',
              url: 'https://www.ka1che.com/#about',
              desc:
                '陕西卡一车物流科技有限公司成立于2015年，总部位于丝绸之路的起点古城西安，是一家以物流运输及大宗商品供应链服务为主的综合型现代物流企业。注册资金5000万。经过近几年的发展，公司业务规模不断扩大，经营项目不断增多，已涉及到物流运输、煤炭贸易、电子科技、保险经纪等行业。'
            },
            {
              name: '大宗智联',
              url: 'https://tc.dzzl56.com/dist/rwgvBeW2zE9Y/',
              desc:
                '大宗智联公转铁智能调度系统是一款专为大宗货物运输行业设计的智能化解决方案，旨在提高货物从公路到铁路的运输效率，降低运输成本，同时增强运输过程中的安全性和可追溯性。'
            },
            {
              name: '陕汽天行健',
              url: 'https://www.sqtxj.com/pc/index.html',
              desc:
                  '天行健车联网服务成功应用于多领域，其中为全国100多家物流公司提供车队服务，为500多家车贷商提供风控服务，为全国8个地市政府渣土车监管单位提供技术支持，为多家零部件企业提供大数据定制服务。'
            },
          ]
        },
        {
          label: '厨电行业',
          value: 'cooker',
          bg: require('@/assets/img/trades/chuFang.jpg'),
          icon: 'icon-youyanji',
          sence:
            '提供基于传感和人工智能技术的烹饪曲线模型。例如老板电器-中式烹饪曲线的建立。已开始，通过AI人工智能精准追踪时间和温度两大核心指标，掌控每道菜式的烹饪节奏，建设中式烹饪曲线数据库，形成烹饪曲线优化，模型进一步推动厨电的智能升级，提升用户体验。',
          tech:
            '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；制造服务数据的安全隐私保护。',
          projects: [
            // {name: '政企融合平台', img: require('@/assets/img/mh/zqrh.png'), url: 'http://139.196.201.183:4010', desc: '协同整合政府与各类优质社会服务资源，集政务服务、产融合作、企业服务等功能于一体，为企业提供在线综合服务入口的专业化公共信息平台'},
            {
              name: '恒温智能控制平台',
              img: require('@/assets/img/mh/gin.png'),
              url: 'https://gin.somfgs.cn/#/',
              desc:
                '基于大数据挖掘的设备运行状态诊断评价系统，实现设备运行状态在线监测、设备评分的数据可视化和故障诊断'
            },
            {
              name: '食材分析平台',
              img: require('@/assets/img/mh/frypan.png'),
              url: 'https://frypan.somfgs.cn/#/',
              desc:
                '以质量预测技术为基础，以设备内的单片机为技术承载对象，结合QT上位机和数据提取上位机的软件分析'
            }
          ]
        },
        {
          label: '纺织行业',
          value: 'textile',
          bg: require('@/assets/img/trades/fangZhi.jpg'),
          icon: 'icon-duanxiujikouyifu',
          sence:
            '提供纺织服装全产业敏捷供应链管理，实现产业链上下游敏捷协同。上游由供应商乃至供应商的供应商组成的企业合作伙伴关系，下游由生产商、分销商、零售商、终端消费者组成的用户关系。面向供应链的统一管理，促进信息流、资金流、物流的协同整合，提升供应链整体的效率和效益。例如阿里云IoT技术帮助企业实现实时控制和智能制造，大幅提高行业供应链能力，助力服装行业整体效率水平提升。5G MES技术应用于服装行业，推动工厂侧云、边、端的数据数据闭环，最终实现服装行业产业链透明互联。秒优等合作伙伴依托服装行业工业互联网平台，形成了可快速复制的智能制造创新模式，数据驱动整个服装生产流程，通过系统集成、精益管理、IoT技术、智能排产、智能在线调度、数字化绩效手段，将原本大批量生产模式转化为连续的、小批量、个性化、高效益的生产模式，真正做到快速精准去库存，实现小单快返的终极目标。',
          tech: '网络驱动的制造产品服务化设计；制造服务智能交易技术。',
          projects: [
            {
              name: '管理平台',
              img: require('@/assets/img/mh/indatax.png'),
              url: 'https://spinning.somfgs.cn',
              desc: '该管理平台通过网络驱动的制造产品服务化设计和制造服务智能交易技术，实现了纺织服装行业的敏捷供应链管理。平台促进产业链上下游企业的协同，提升信息流、资金流和物流的协同整合能力，提高供应链效率和效益。结合阿里云IoT和5G MES技术，实现实时控制和智能制造，推动数据闭环和透明互联。秒优等合作伙伴依托该平台，形成了可快速复制的智能制造模式，通过系统集成、智能排产和智能在线调度等手段，实现个性化、高效益的生产，快速精准去库存，达到小单快返的目标。'
            }
          ]
        },
        {
          label: '包装行业',
          value: 'packaging',
          bg: require('@/assets/img/trades/baoZhuang.jpg'),
          icon: 'icon-wuliaobaozhuangdan',
          sence:
            '提供基于AI+AR的包装定制化设计服务。提供具有专业包装设计能力的人工智能和增强现实服务，客户只需在包装商城上输入包装设计需求，就会自动快速提供若干种方案供其选择，并且能呈现最终的三维立体、增强现实效果。具有强大的学习能力，学习用户习惯、市场偏好等等，这些都将成为它的“知识储备”，更好地为用户提供包装设计服务。',
          tech:
            '基于数字孪生和工业互联网的数字化服务技术；产品与服务系统设计优化方法。',
          projects: [
            {
              name: '世纪开元',
              desc:
                '世纪开元致力构建小批量印刷包装及个性定制生态系统，为全球企业和个人用户提供一站式场景化解决方案。世纪开元有影像定制、商务印刷、包装印刷、广告图文、文创产品、企业定制的六大领域、数千类产品。',
              url: 'http://www.zheliyin.com/'
            }
            // {name: '制造服务数据价值评估平台', img: require('@/assets/img/mh/evaluation.png'), url: 'https://evaluation.somfgs.cn/', desc: '制造服务数据价值评估系统针对服务型制造服务系统产生的海量数据，建立数据价值评估一体化系统，涵盖评估需求管理、模型训练优化、数据价值评估与多视角分析等功能，当前评估模型数10+'},
          ]
        },
        {
          label: '家具行业',
          value: 'furniture',
          bg: require('@/assets/img/trades/jiaJu.jpg'),
          icon: 'icon-shafa',
          sence:
            '提供易简大规模个性化定制服务模式，实现“先设计，再销售，后生产”。破解定制家居生产的周期长、出错率高、品质差和规模生产难的难题，实现了规模个性化设计、大规模个性化定制与大规模个性化服务，有效把制造与服务融合，为消费者提供一站式的全新的定制家居消费体验。',
          tech:
            '基于数字孪生和工业互联网的数字化服务技术；基于人工智能技术的制造和服务深度融合。',
          projects: [
            {
              name: 'IMS20智能制造平台',
              img: require('@/assets/img/mh/fdm.png'),
              url: 'https://fdm.somfgs.cn/',
              desc:
                '针对各种生产约束条件，快速制定优化的生产作业计划，缩短制造提前期，削减库存，全面提速，提前进入交货期，为生产计划提供最优解'
            },
            {
              name: 'OMS20订单平台',
              img: require('@/assets/img/mh/oms.png'),
              url: 'https://oms.somfgs.cn/',
              desc:
                '实现订单从创建到交付的全生命周期管理，通过系统化的处理确保信息高效流转，使订单信息全流程透明化管理'
            },
            {
              name: 'Meta20设计平台',
              img: require('@/assets/img/mh/design.png'),
              url: 'https://design.somfgs.cn/',
              desc:
                '平台借助云端图库、云渲染架构，可高效、易用的完成全屋房型的绘制、进行三维空间产品的布置、快速输出所需要的效果图、设计效果所见即所得，目前用户5000+'
            }
          ]
        },
        // {
        //   label: '生鲜食品行业',
        //   value: 'food',
        //   bg: require('@/assets/img/trades/shengXian.jpg'),
        //   icon: 'icon-shuiguo',
        //   sence:
        //     '提供冷链物流全过程、全周期服务，实现冷链全过程监测与追溯，检验检疫服务。例如阿里的盒马鲜生。盒马运用大数据、移动互联、智能物联网、自动化等技术及先进设备，实现人、货、场三者之间的最优化匹配，平台拥有从供应链、仓储到配送的完整物流体系。',
        //   tech:
        //     '网络驱动的产品服务化设计；服务数据交换方法、定价模型和智能交易技术。',
        //   projects: [
        //     // {name: '数据中台', img: require('@/assets/img/mh/indatax.png'), url: 'https://indatax.somfgs.cn/', desc: '为企业提供一站式的数据服务，提供异构数据柔性接入、数据管理、数据计算等功能，能够有效提高数据的管理、利用数据的效率。'},
        //   ]
        // },
        {
          label: '电梯行业',
          value: 'elevator',
          bg: require('@/assets/img/trades/dianTi.jpg'),
          icon: 'icon-dianti-xiantiao',
          sence:
            '提供电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。例如，通过工业互联网的规则引擎与阿里云其他云产品打通，实现采集+计算+存储+AI+可信的全栈服务，充分挖掘物联网数据的价值，实现连接到智能的跨越；通过构建数据平台，提供设备预测与分析统计服务，可以实现电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。基于可信电梯物联网平台实施的垂直电梯远程监测解决方案，采用不触碰电梯本身的安装方式，快速适配老旧电梯，采集上报各种数据，比如电梯运行状态信息，是否有人信息、所处楼层信息、实时故障等信息，接入到远程监控系统。结合后台数据为客户提供故障分析服务以及故障处理救援服务、智能维护服务等。',
          tech:
            '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；全生命周期的制造服务数据治理。',
          projects: [
            {
              name: '管理平台',
              img: require('@/assets/img/mh/indatax.png'),
              url: 'https://lift.somfgs.cn',
              desc: '该管理平台通过集成传感和智能技术，实现了电梯设备的远程管理和维护。平台能够采集并分析电梯的运行状态、所处楼层、是否有人以及实时故障等数据，提供设备预测性维护、全生命周期管理以及透明的监管服务。结合阿里云的工业互联网规则引擎和其他云产品，平台实现了数据采集、计算、存储、AI分析及安全保障的全栈服务，充分挖掘物联网数据的价值，帮助客户实现智能维护和故障救援。'
            }
          ]
        },
        {
          label: '精密模具行业',
          value: 'mechanical',
          bg: require('@/assets/img/trades/muJu.jpg'),
          icon: 'icon-yewumoban',
          sence:
            '采用“平台+小微”方式，实现“共享制造”。例如深圳市五鑫科技的“Mould Lao众创空间”，以赋能的方式帮助平台内小微企业及各个技术创客实现从被动劳动变成自主竞价模式下的技能共享。小微企业的每笔订单，以内部市场竞标的方式由小微及创客自主报价，相互竞标。竞标成功后，平台通过共享制造系统对每个创客的工作进度和制造品质进行实时跟踪，他们完成一天的工作后可以立即扫码结算。平台将大量小微企业整合到一起，通过大数据、人工智能等技术，使供需之间能够实现智能化高效匹配。',
          tech:
            '服务数据交换方法、定价模型和智能交易技术；网络驱动的协同制造技术。',
          projects: [
            // {name: '联邦学习', img: require('@/assets/img/mh/ysjs.png'), url: 'https://lbxx.somfgs.cn', desc: '多个数据拥有方在保持数据本地化的同时，共同训练一个机器学习模型'},
            {
              name: '共享平台-微星网',
              img: require('@/assets/img/mh/share.png'),
              url: 'https://share.somfgs.cn',
              desc:
                '帮助企业快速交付业务应用需求、以最小化手工代码量实现快速上线交付，目前已经在模具制造、注塑成型、线束组装、电子加工等多个行业成功实践'
            },
            {
              name: '模塑ERP管理系统',
              img: require('@/assets/img/mh/code.png'),
              url: 'https://code.somfgs.cn',
              desc:
                '推动注塑事业部和模具事业部的数字化管理升级，全面支持客户报价、接单、生产排程、采购计划、仓储、品质、财务等作业环节的信息化管控'
            }
          ]
        }
      ]
      // menuItems: [
      //   {
      //     label: '设计与制造',
      //     value: 'design',
      //     icon: 'icon-sheji',
      //     projects: [
      //     ]
      //   },
      //   {
      //     label: '数据管理与分析',
      //     value: 'data',
      //     icon: 'icon-caiwuxitongjiekou',
      //     projects: [
      //     ]
      //   },
      //   {
      //     label: '设备与运维管理',
      //     value: 'equipment',
      //     icon: 'icon-shebeiyunwei',
      //     projects: [
      //     ]
      //   },
      //   {
      //     label: '行业特定平台',
      //     value: 'industry',
      //     icon: 'icon-hangye-quanbuhangye',
      //     projects: [
      //
      //     ]
      //   },
      //   {
      //     label: '订单与供应链管理',
      //     value: 'order',
      //     icon: 'icon-timeManager',
      //     projects: [
      //     ]
      //   },
      //   {
      //     label: '企业服务与信用管理',
      //     value: 'service',
      //     icon: 'icon-qiyefuwu',
      //     projects: [
      //     ]
      //   },
      //   {
      //     label: '食材与质量分析',
      //     value: 'food',
      //     icon: 'icon-hechangzonglan',
      //     projects: [
      //     ]
      //   },
      //   {
      //     label: '共享与交付平台',
      //     value: 'share',
      //     icon: 'icon-shujugongxiang',
      //     projects: [
      //     ]
      //   },
      // ]
    }
  },
  computed: {
    activeObj() {
      return this.menuItems.find(item => item.value === this.activeValue)
    },
    activeProjects() {
      const activeMenu = this.menuItems.find(
        item => item.value === this.activeValue
      )
      return activeMenu.projects || []
    },
    projectsBannerList() {
      if (this.activeProjects.length < 4) {
        return [this.activeProjects]
      } else {
        // 拆分成二维数组，每三个项目一行
        const result = []
        let row = []
        for (let i = 0; i < this.activeProjects.length; i++) {
          row.push(this.activeProjects[i])
          if (row.length === 3) {
            result.push(row)
            row = []
          }
        }
        if (row.length > 0) {
          result.push(row)
        }
        return result
      }
    }
  },
  methods: {
    handleProjectClick(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.application {
  width: 100%;
  margin-top: 0;
  padding: 0;
  .application--header {
    margin-top: -20px;
    width: 100%;
    height: 40vh;
    background-color: #333;
    //background: var(--header-bg-img) no-repeat center center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    filter: grayscale(10%);
    position: relative;
    .header__nav {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(#fff, 0.5);
      backdrop-filter: blur(2px);
    }
    .header__content {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.2);
      backdrop-filter: grayscale(70%) blur(2px);
      position: absolute;
      box-sizing: border-box;
      padding: 0 80px;
      //margin: 0 auto;
      overflow: hidden;
      .header__title {
        font-size: 32px;
        color: #fff;
        font-weight: lighter;
        margin-top: 5vh;
      }
      .header__desc {
        max-width: 50%;
        line-height: 2;
        color: rgba(#fff, 0.8);
        margin-top: 10px;
      }
    }
  }
  &__content {
    padding: 0 80px;
    margin: 0 auto;
  }
}

.project-container {
  height: 100%;
  display: flex;
  .project-item {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    .project-item--container {
      margin-top: 100px;
      position: relative;
      height: 80%;
      max-width: 80%;
      min-width: 200px;
      margin: 0 auto;
      padding: 10px;
      .project-item--tips {
        position: relative;
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
        overflow: hidden;
        &:before {
          position: absolute;
          display: block;
          content: "";
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 4px solid #ff9123;
          z-index: 2;
        }
        &:after {
          position: absolute;
          display: block;
          content: "";
          right: 0;
          bottom: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #cfcfcf;
          z-index: 1;
        }
      }
      .project-item--label {
        font-size: 24px;
        line-height: 1.5;
        color: #333;
        margin-bottom: 30px;
      }
      .project-item--line {
        height: 4px;
        width: 15px;
        border-radius: 99px;
        background-color: #cdcdcd;
        margin-bottom: 10px;
      }
      .project-item--desc {
        font-size: 16px;
        color: #666;
        line-height: 2;
        /*** 多行溢出 ***/
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .project-item--count {
        position: absolute;
        bottom: 30%;
        left: 0;
        display: flex;
        align-items: flex-end;
        .current {
          font-size: 24px;
          font-weight: bold;
          color: #333;
          margin: 0 10px;
        }
        .separator {
          font-size: 14px;
          color: #d3d2d2;
          transform: rotate(40deg);
        }
        .total {
          font-size: 14px;
          color: #999;
          margin: 0 10px;
          margin-bottom: 2px;
        }
      }
    }

    .footer {
      opacity: 0;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(210, 25, 0, 0.7);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: opacity 0.3s ease-in-out;
    }
  }
  .project-item:nth-of-type(even) {
    .project-item--tips {
      &:before {
        left: 50%;
        top: 4px;
        transform: translateX(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 4px solid #ff9123;
      }
      &:after {
        left: 0;
        margin: auto;
        bottom: -18px;
        width: 30px;
        height: 30px;
        border-radius: 0;
        transform: rotate(45deg);
        background-color: #cfcfcf;
      }
    }
  }
}

.project-item:hover {
  background-color: rgba(#000, 0.01);
  background-image: var(--bg-img);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  backdrop-filter: grayscale(100%);
  .footer {
    opacity: 1;
  }
  //&:before {
  //  display: block;
  //  content: "";
  //  position: absolute;
  //  top: 50%;
  //  transform: translateY(-50%);
  //  left: -10px;
  //  width: 60px;
  //  height: 150%;
  //  border-radius: 50%;
  //  background-color: #f5f7fa;
  //  z-index: 99;
  //}
}

/** 多行溢出 */
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
