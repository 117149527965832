<template>
  <div class="nav-bar grid-box">
    <div
        v-for="item in menuItems"
        :key="item.value"
        class="nav-item"
        :class="{
          'nav-item--animation': item.value === value,
          'nav-item--active': clickValue === item.value
        }"
        @click="handleClick(item.value)"
    >
      <div class="nav-item--content">
        <div :class="['iconfont f18', item.icon]"></div>
        <div class="nav-item--label">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    value: {
      type: String,
      default: 'all'
    },
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      interval: null,
      timer: null,
      clickValue: null
    }
  },
  created() {
    this.startAnimation()
  },
  beforeDestroy() {
    this.clearAnimation()
    this.clearTimer()
  },
  methods: {
    /**
     * 点击菜单项的事件处理函数
     * @param value
     */
    handleClick (value) {
      this.clearAnimation()
      this.clickValue = value
      this.$emit('input', value)
      this.clearTimer()
      this.timer = setTimeout(() => {
        this.clickValue = null
        this.$emit('input', this.getNextValue(value))
        this.startAnimation()
      }, 30000)
    },
    clearTimer () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    /**
     * 根据value值，获取下一个菜单项的value值
     * 如果当前菜单项是最后一个，则返回第一个菜单项的value值
     * @param {String} value 当前菜单项的value值
     */
    getNextValue (value) {
      const index = this.menuItems.findIndex(item => item.value === value)
      if (index === this.menuItems.length - 1) {
        return this.menuItems[0].value
      } else {
        return this.menuItems[index + 1].value
      }
    },
    /**
     * 开始动画
     */
    startAnimation () {
      this.clearAnimation()
      this.interval = setInterval(() => {
        this.$emit('input', this.getNextValue(this.value))
      }, 10000)
    },
    /**
     * 清除动画
     */
    clearAnimation () {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.nav-bar {
  overflow: hidden;
}
.grid-box {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.nav-item {
  position: relative;
  user-select: none;
  flex: 1;
  padding: 16px 10px;
  cursor: pointer;
  border-right: 1px solid rgba(#000, 0.3);
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:last-of-type {
    border: none;
  }
  .nav-item--content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    overflow: hidden;
    .iconfont {
      font-size: 32px;
      color: #333;
      margin-bottom: 10px;
    }
    .nav-item--label, {
      font-size: 16px;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(#ff9123, 0.7);
    z-index: 9;
  }
}

.nav-item--animation {
  background-color: transparent;
  border-color: rgba(#000, 0.3);
  .iconfont {
    color: #333;
  }
  .nav-item--label {
    color: #333;
  }
  &:before {
    animation: nav-item-active-animation 10s ease-in forwards;
  }
}

.nav-item--active {
  background-color: #ff9123;
  font-weight: bold;
  border-color: transparent;
  .iconfont {
    color: #fff !important;
  }
  .nav-item--label {
    color: #fff !important;
  }
}

@keyframes nav-item-active-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
