import Vue from "vue";
import VueRouter from "vue-router";
import { clearLoginInfo } from "@/utils";

Vue.use(VueRouter);

// 全局路由，这部分是独立的页面
const commonRoutes = [
  {
    path: "/404",
    name: "404",
    component: () => import("../views/common/404"),
    meta: { title: "404未找到" }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/common/login"),
    meta: { title: "登录" }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/common/register"),
    meta: { title: "注册" }
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: () => import("../views/common/forget-password"),
    meta: { title: "忘记密码" }
  },
  {
    path: "/zykjfw",
    name: "zykjfw",
    component: () => import("../views/zykjfw/zykjfw"),
    meta: { title: "中原科技服务" }
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../views/index/activity/shenzhen_activity"),
    meta: { title: "深圳活动" }
  },
  {
    path: "/home-default",
    name: "defaultHome",
    component: () => import("../views/home-default"),
    meta: { title: "首页" }
  }
];

// 商城路由
const routes = [
  // 默认进入商城的主页
  {
    path: "/",
    name: "main",
    component: () => import("../views/main"),
    redirect: { name: "index" },
    children: [
      {
        path: "/tcinfo",
        name: "tc",
        meta: { title: "标准技术委员会列表" },
        component: () => import("../views/tc/tc-search")
      },
      // 商城主页和个人中心是平级的
      {
        path: "/index",
        name: "index",
        meta: {
          title: "主页",
          showLeftMenu: true,
          expandLeftMenu: true,
          showBreakCrumb: false
        },
        component: () => import("../views/index/self-service")
      },
      {
        path: "evaluation-zjtx",
        name: "evaluation-zjtx",
        meta: {
          title: "专精特新企业自评价",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/evaluation/zjtx/evaluate")
      },
      {
        path: "evaluation-xiaojuren",
        name: "evaluation-xiaojuren",
        meta: {
          title: "专精特新小巨人企业自评价",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/evaluation/xiaojuren/evaluate")
      },
      {
        path: "evaluation-creative",
        name: "evaluation-creative",
        meta: {
          title: "创新型企业自评价",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/evaluation/creative/evaluate")
      },
      // 三种服务方式首页
      {
        path: "/serve",
        name: "serve",
        meta: {
          title: "首页",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        // component: () => import('../views/index/serve')
        component: () => import("../views/index/serve-new")
      },
      // 全部服务
      {
        path: "/industry-chain",
        name: "industry-chain",
        meta: {
          title: "产业链",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/industry-chain/index.vue")
      },
      // 全部服务
      {
        path: "/all-service",
        name: "all-service",
        meta: {
          title: "全部服务",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/all-service/index.vue")
      },
      {
        path: "/demand-hall",
        name: "demand-hall",
        meta: {
          title: "需求大厅",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/demand-hall/index.vue")
      },
      {
        path: "/news",
        name: "news",
        meta: {
          title: "新闻公告",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/news/index")
      },
      {
        path: "/application",
        name: "application",
        meta: {
          title: "应用示范",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/application/indexBeta")
      },
      {
        path: "/trades-detail",
        name: "trades-detail",
        meta: {
          title: "行业详情",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/trades-detail/index.vue")
      },
      {
        path: "/data-support",
        name: "data-support",
        meta: {
          title: "数据支撑",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/data-support/index.vue")
      },
      {
        path: "/data-trading",
        name: "data-trading",
        meta: {
          title: "数据交易",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/data-trading/index.vue")
      },
      {
        path: "/one-stop",
        name: "one-stop",
        meta: {
          title: "一站式服务",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/one-stop")
      },
      {
        path: "/support",
        name: "support",
        meta: {
          title: "服务商招募",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/support/support")
      },
      {
        path: "/technical-service",
        name: "technical-service",
        meta: {
          title: "技术服务",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/technical-service/service-list")
      },
      {
        path: "/online-diagnosis",
        name: "online-diagnosis",
        meta: {
          title: "质量在线诊断",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/online-diagnosis/list")
      },
      {
        path: "/quality-doctor",
        name: "quality-doctor",
        meta: {
          title: "质量在线诊断",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/quality-doctor")
      },
      {
        path: "/service-info",
        name: "service-info",
        meta: {
          title: "技术服务详情",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/technical-service/service-info")
      },
      {
        path: "/recommend",
        name: "recommend",
        meta: {
          title: "推荐式服务",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/recommend/recommend-index")
      },
      {
        path: "/recommend-result-word2vec",
        name: "recommend-result-word2vec",
        meta: {
          title: "质量安全监测",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/recommend/recommend-word2vec")
      },
      {
        path: "/recommend-result-cqc",
        name: "recommend-result-cqc",
        meta: {
          title: "服务协同与动态适配",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/recommend/recommend-result-cqc")
      },
      {
        path: "/recommend-result-brand",
        name: "recommend-result-brand",
        meta: {
          title: "品牌价值在线测算",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/recommend-result-brand")
      },
      {
        path: "/recommend-credit",
        name: "recommend-credit",
        meta: {
          title: "信用等级在线评价",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/recommend/recommend-credit")
      },
      {
        path: "/recommend-general-quality",
        name: "recommend-general-quality",
        meta: {
          title: "企业综合质量诊断",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () =>
          import("../views/index/recommend/general-quality-result")
      },
      {
        path: "/recommend-result-jingqi",
        name: "recommend-result-jingqi",
        meta: {
          title: "企业景气监测预警",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () =>
          import("../views/index/recommend/recommend-result-jingqi")
      },
      {
        path: "/recommend-result-chuangxin",
        name: "recommend-result-chuangxin",
        meta: {
          title: "企业创新效率评价",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () =>
          import("../views/index/recommend/recommend-result-chuangxin")
      },
      {
        path: "/implement",
        name: "implement",
        meta: {
          title: "活动页",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/implement/implement")
      },
      // {
      //   path: '/activity',
      //   name: 'activity',
      //   meta: { title: '深圳活动页', showLeftMenu: false, expandLeftMenu: false, showBreakCrumb: false },
      //   component: () => import('../views/index/activity/shenzhen_activity')
      // },
      {
        path: "/search",
        name: "search",
        meta: {
          title: "搜索页",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false,
          keepAlive: true
        },
        component: () => import("../views/search/search")
      },
      {
        path: "/prefecture",
        name: "prefecture",
        meta: {
          title: "服务专区",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/index/prefecture/prefecture"),
        children: [
          {
            path: "/zryt",
            name: "zryt",
            component: () => import("../views/index/prefecture/zhongrenyingtai")
          },
          {
            path: "/rjpc",
            name: "rjpc",
            component: () =>
              import("../views/index/prefecture/software-evalution")
          },
          {
            path: "/cqc",
            name: "cqc",
            component: () => import("../views/index/prefecture/cqc")
          },
          {
            path: "/gongsibao",
            name: "gongsibao",
            component: () => import("../views/index/prefecture/gongsibao")
          }
        ]
      },
      {
        path: "/service",
        name: "service",
        meta: {
          title: "产品详情",
          showLeftMenu: true,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/service/service-detail.vue")
      },
      {
        path: "/payment",
        name: "payment",
        meta: {
          title: "提交订单",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/service/payment.vue")
      },
      {
        path: "/pay-successful",
        name: "pay-successful",
        meta: {
          title: "支付成功",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/service/pay-successful.vue")
      },
      {
        path: "/shopping-cart",
        name: "shoppingCart",
        meta: {
          title: "购物车",
          showLeftMenu: true,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/shoppingcart/shopping-cart")
      },
      {
        path: "/news-notice-page",
        name: "newsNoticePage",
        meta: {
          title: "新闻中心",
          showLeftMenu: false,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/newscenter/news-notice-page")
      },
      {
        path: "/big-screen",
        name: "bigScreen",
        component: () =>
          import("../views/manage/modules/tech-analysis/big-screen")
        // component: () => import('../views/manage/modules/tech-analysis/earth-test')
      },
      {
        path: "/manage",
        name: "manage",
        meta: {
          title: "个人中心",
          showLeftMenu: true,
          expandLeftMenu: false,
          showBreakCrumb: false
        },
        component: () => import("../views/manage/manage"),
        redirect: { name: "home" },
        children: [
          {
            path: "/home",
            name: "home",
            component: () => import("../views/manage/modules/home/home")
          },
          {
            path: "/account",
            name: "account",
            component: () => import("../views/manage/modules/account/index")
          },
          {
            path: "/need",
            name: "need",
            component: () => import("../views/manage/modules/need/need")
          },
          {
            path: "/solution-pending",
            name: "solution-pending",
            component: () =>
              import("../views/manage/modules/solution/need-solution-pending")
          },
          {
            path: "/solution-completed",
            name: "solution-completed",
            component: () =>
              import("../views/manage/modules/solution/need-solution")
          },
          {
            path: "/word2vec",
            name: "word2vec",
            component: () =>
              import("../views/manage/modules/tech-component/word2vec")
          },
          {
            path: "/brandValue",
            name: "brandValue",
            component: () =>
              import("../views/manage/modules/tech-component/brand-value")
          },
          {
            path: "/credit",
            name: "credit",
            component: () =>
              import("../views/manage/modules/tech-component/credit")
          },
          {
            path: "/tech-analysis",
            name: "tech-analysis",
            component: () =>
              import("../views/manage/modules/tech-analysis/tech-analysis")
            // component: () => import('../views/manage/modules/tech-analysis/big-screen.vue')
            // component: () => import('../views/manage/modules/tech-analysis/earth-test')
          },
          {
            path: "/enterprise",
            name: "enterprise",
            component: () =>
              import("../views/manage/modules/enterprise/enterprise")
          },
          {
            path: "/remind",
            name: "remind",
            component: () => import("../views/manage/modules/message/remind")
          },
          {
            path: "/collect",
            name: "collect",
            component: () => import("../views/manage/modules/collect/collect")
          },
          {
            path: "/view",
            name: "view",
            component: () => import("../views/manage/modules/view/view")
          },
          {
            path: "/my-order",
            name: "my-order",
            component: () => import("../views/manage/modules/order/my-order")
          },
          {
            path: "/consulting",
            name: "consult",
            component: () =>
              import("../views/manage/modules/consult/consulting")
          },
          //  发票管理--收票地址
          {
            path: "/invoice-address",
            name: "invoice-address",
            component: () =>
              import("../views/manage/modules/invoice/invoice-address")
          },
          {
            path: "/invoice-rise",
            name: "invoice-rise",
            component: () =>
              import("../views/manage/modules/invoice/invoice-rise")
          },
          {
            path: "/invoice-query",
            name: "invoice-query",
            component: () =>
              import("../views/manage/modules/invoice/invoice-query")
          }
        ],
        beforeEnter(to, from, next) {
          let token = Vue.cookie.get("token");
          if (!token || !/\S/.test(token)) {
            clearLoginInfo();
            next({ name: "login" });
          }
          next();
        }
      }
    ]
  },
  //  质量医生 -- 微信页面
  {
    path: "/h5-WeChat",
    name: "h5-WeChat",
    component: () => import("../views/h5/h5-WeChat"),
    children: [
      {
        path: "/authorization",
        name: "authorization",
        component: () => import("../views/h5/authorization")
      },
      //  授权回调页
      {
        path: "/authorization-back",
        name: "authorization-back",
        component: () => import("../views/h5/authorization-back")
      },
      //  h5--登录页
      {
        path: "/h5-login",
        name: "h5-login",
        component: () => import("../views/h5/h5-login")
      },
      //  模块选择
      {
        path: "/service-module",
        name: "service-module",
        component: () => import("../views/h5/service-module")
      },
      //  用户聊天框
      {
        path: "/chat-user",
        name: "chat-user",
        component: () => import("../views/h5/component/chat-user")
      },
      //    用户-消息列表
      {
        path: "/chat-list",
        name: "chat-list",
        component: () => import("../views/h5/component/chat-list")
      }
    ]
  },
  // 个人服务-搜索页(与公共搜索页共用)
  {
    path: "/search-personal",
    name: "search-personal",
    meta: {
      title: "搜索页",
      showLeftMenu: false,
      expandLeftMenu: false,
      showBreakCrumb: false,
      keepAlive: true
    },
    component: () => import("../views/search/search")
  },
  // 个人服务-产品详情页
  {
    path: "/service-personal",
    name: "service-personal",
    meta: {
      title: "产品详情",
      showLeftMenu: true,
      expandLeftMenu: false,
      showBreakCrumb: false
    },
    component: () => import("../views/service/service-detail.vue")
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  routes: [].concat(commonRoutes, routes)
});

// 找不到页面
router.addRoutes([{ path: "*", redirect: { name: "404" } }]);

// 跳转页面到页面顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
