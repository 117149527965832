<template>
  <div class="search">
    <div style="padding: 14px 36px">
      <div class="search-box" v-if="visible">
        <template v-for="index in maxLevel">
          <div
            v-if="
              (index > 1 &&
                dataForm.itemCode.length >= indexLen[index - 1].len) ||
                index === 1
            "
            class="search-line"
            :key="'serviceItem' + index"
          >
            <div class="search-line-label">
              <span>{{ indexLen[index].title }}</span>
            </div>
            <div class="search-line-content">
              <div class="search-line-content-box">
                <div style="flex: auto;max-width: 100%">
                  <div
                    class="content-box-select"
                    :class="{
                      'content-box-select-expand':
                        expandOption['expand' + index]
                    }"
                  >
                    <span
                      class="content-box-select-item"
                      :class="{
                        on:
                          serviceItemChoose['itemChoose' + index].itemCode ===
                          'all'
                      }"
                      @click="
                        itemClickListener(index, {
                          itemCode: 'all',
                          itemName: '全部'
                        })
                      "
                      >全部</span
                    >
                    <span
                      class="content-box-select-item"
                      :class="{
                        on:
                          serviceItemChoose['itemChoose' + index].itemCode ===
                          item.itemCode
                      }"
                      v-for="item in itemDataList['dataList' + index]"
                      :key="item.itemCode"
                      @click="itemClickListener(index, item)"
                    >
                      {{ item.itemName }}
                    </span>
                    <!--                                        <el-link style="position: absolute;top: 0;right: 0" type="primary"-->
                    <!--                                                 :underline="false" @click="switchExpand(index)">-->
                    <!--                                            {{ expandOption['expand' + index] ? '收起' : '展开' }}-->
                    <!--                                            <i :class="expandOption['expand' + index] ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>-->
                    <!--                                        </el-link>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="search-line">
          <div class="search-line-label">
            <span>服务方式</span>
          </div>
          <div class="search-line-content">
            <div class="search-line-content-box">
              <div style="flex: auto;max-width: 100%">
                <div class="content-box-select">
                  <span
                    class="content-box-select-item"
                    :class="{ on: dict.dictId === dataForm.serviceMethod }"
                    v-for="dict in serviceMethodDict"
                    :key="dict.dictId"
                    @click="chooseMethod(dict.dictId)"
                  >
                    {{ dict.dictName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uf uf-ac" style="position: relative">
          <div class="search-line-label" style="width: 62px">
            <span>排序</span>
          </div>
          <div>
            <el-radio-group
              v-model="dataForm.sortType"
              size="small"
              @change="searchData"
            >
              <el-radio-button label="">综合排序</el-radio-button>
              <el-radio-button label="serviceCount">销量</el-radio-button>
              <el-radio-button label="itemComment">评价</el-radio-button>
              <el-radio-button label="createTime">时间</el-radio-button>
            </el-radio-group>
          </div>
          <!--          <div class="search-line-label ml-5" style="margin-right: 5px">-->
          <!--            <span>价格</span>-->
          <!--          </div>-->
          <!--          <div class="uf uf-ac search-price" style="position: relative;padding-right: 52px" @mouseenter="priceEnterVisible = true" @mouseleave="priceEnterVisible = false">-->
          <!--            <el-input class="mr-1" style="width: 65px;text-align: left" size="mini" v-model="dataForm.priceLow" placeholder="￥" v-enter-int clearable></el-input>-->
          <!--            <span>-</span>-->
          <!--            <el-input class="ml-1" style="width: 65px;text-align: left" size="mini" v-model="dataForm.priceHigh" placeholder="￥" v-enter-int clearable></el-input>-->
          <!--            <el-button v-show="priceEnterVisible" style="position: absolute;right: 0" type="primary" size="mini" @click="searchData">确认</el-button>-->
          <!--          </div>-->
          <!--          <div class="search-line-label ml-4" style="margin-right: 5px">-->
          <!--            <span>服务地区</span>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <org-select-popover></org-select-popover>-->
          <!--          </div>-->
          <div class="uf uf-ac search-btn ml-3 uf-f1">
            <el-input
              placeholder="请输入搜索内容"
              class="mr-2"
              v-model="dataForm.serviceName"
              @keyup.enter.native="getDataList(true)"
            ></el-input>
            <el-button
              type="primary"
              class="button"
              icon="el-icon-search"
              @click="getDataList(true)"
              >搜索
            </el-button>
          </div>
          <div class="uf uf-ac">
            <el-divider direction="vertical"></el-divider>
            <div class="ml-2">
              共 <span class="fwb f18 c-base">{{ totalCount }}</span> 件产品
            </div>
            <div class="ml-3 mr-2">
              <span class="c-base">{{ pageIndex }}</span>
              <span>/</span>
              <span>{{ totalPage }}</span>
            </div>
            <el-pagination
              small
              background
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-size="pageSize"
              :total="totalCount"
              layout="prev, next"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 0 36px 20px 36px" v-loading="loading">
      <div class="list-box uf uf-pj fwrap-w tal">
        <template v-if="dataList.length > 0">
          <service-card
            v-for="item in dataList"
            :service="item"
            @showDetail="chooseProduct(item)"
            :key="item.id"
          />
          <div class="zhanwei" style="width: 430px"></div>
          <div class="zhanwei" style="width: 430px"></div>
        </template>
        <template v-else>
          <div class="tac wi100" style="height: 300px">
            <icon-svg
              class="mt-3"
              style="font-size: 200px"
              name="wushuju"
            ></icon-svg>
            <div class="f14" style="color: #909399">暂无数据</div>
          </div>
        </template>
        <div class="tac wi100 mt-4 mb-2">
          <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkIsNull } from '@/utils'
import routerTransfer from '../../utils/routerTransfer'
import ServiceCard from '../components/service-card'

export default {
  name: 'search',
  components: {
    ServiceCard
  },
  data() {
    return {
      visible: false,
      maxLevel: 1,
      expandOption: {},
      serviceItemChoose: {},
      serviceItemList: [],
      itemDataList: {},
      dataForm: {
        serviceName: '',
        itemCode: '',
        sortType: '',
        serviceMethod: '0',
        orgCode: '',
        priceLow: null,
        priceHigh: null
      },
      loading: false,
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0,
      indexLen: {
        1: { len: 2, title: '行业分类' },
        2: { len: 4, title: '二级目录' },
        3: { len: 6, title: '三级目录' },
        4: { len: 8, title: '四级目录' },
        5: { len: 12, title: '五级目录' }
      },
      serviceMethodDict: [
        {
          orderNo: 0,
          groupId: 'SERVICE_METHOD',
          dictName: '全部',
          remark: '服务方式',
          dictId: '0'
        },
        {
          orderNo: 1,
          groupId: 'SERVICE_METHOD',
          dictName: '下单支付',
          remark: '服务方式',
          dictId: '1'
        },
        {
          orderNo: 2,
          groupId: 'SERVICE_METHOD',
          dictName: '专家咨询',
          remark: '服务方式',
          dictId: '2'
        }
      ],
      priceEnterVisible: false
    }
  },
  computed: {
    keepAliveArr: {
      get() {
        return this.$store.state.common.keepAliveArray
      },
      set(arr) {
        this.$store.commit('common/updateKeepAliveArray', arr)
      }
    }
  },
  created() {
    // this.$getDictList('SERVICE_METHOD', (dict) => {
    //     this.serviceMethodDict = dict.SERVICE_METHOD
    // })
    this.dataForm.itemCode = this.$route.params.itemCode || ''
    this.dataForm.serviceName = this.$route.params.serviceName || ''
    this.getServiceItemMenu()
  },
  mounted() {
    routerTransfer.$on(
      'searchEvent',
      function(data) {
        this.dataForm.serviceName = data.serviceName
        this.getDataList(true)
      }.bind(this)
    )
    this.getDataList(true)
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'service') {
      this.keepAliveArr = ['search']
    }
    next()
  },
  beforeDestroy() {
    routerTransfer.$off('searchEvent')
  },
  methods: {
    // 获取服务目录
    getServiceItemMenu() {
      // let menu = localStorage.getItem('serviceItemMenu')
      // if (!checkIsNull(menu)) {
      //     let menuObj = JSON.parse(menu)
      //     this.serviceItemList = menuObj.menu
      //     this.maxLevel = menuObj.maxLevel || 1
      //     this.handleExpandOption()
      //     this.visible = true
      //     return
      // }
      this.serviceItemList = [
        {
          pathName: '/生物医药行业',
          itemCode: '01',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '生物医药行业',
          route: '',
          children: [
            {
              pathName: '/生物医药行业/设计研发',
              itemCode: '0101',
              icon: 'sheji',
              remark: '',
              isLeaf: 0,
              parentId: '01',
              isShow: 0,
              itemName: '设计研发',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/01/0101',
              isProduct: 0,
              id: '0101',
              levelOrder: 1
            },
            {
              pathName: '/生物医药行业/药物发现',
              itemCode: '0102',
              icon: 'biaozhun',
              remark: '',
              isLeaf: 0,
              parentId: '01',
              isShow: 0,
              itemName: '药物发现',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/01/0102',
              isProduct: 0,
              id: '0102',
              levelOrder: 2
            },
            {
              pathName: '/生物医药行业/临床研究',
              itemCode: '0103',
              icon: 'sousuo',
              remark: '',
              isLeaf: 0,
              parentId: '01',
              isShow: 0,
              itemName: '临床研究',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/01/0103',
              isProduct: 0,
              id: '0103',
              levelOrder: 3
            },
            {
              pathName: '/生物医药行业/智能制造',
              itemCode: '0104',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '01',
              isShow: 0,
              itemName: '智能制造',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/01/0104',
              isProduct: 0,
              id: '0104',
              levelOrder: 4
            },
            {
              pathName: '/生物医药行业/售后服务',
              itemCode: '0105',
              icon: 'shouhou',
              remark: '',
              isLeaf: 0,
              parentId: '01',
              isShow: 0,
              itemName: '售后服务',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/01/0105',
              isProduct: 0,
              id: '0105',
              levelOrder: 5
            }
          ],
          levelId: 1,
          pathCode: '/01',
          isProduct: 0,
          id: '01',
          levelOrder: 1
        },
        {
          pathName: '/机械装备行业',
          itemCode: '02',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '机械装备行业',
          route: '',
          children: [
            {
              pathName: '/机械装备行业/研发设计',
              itemCode: '0201',
              icon: 'bianji',
              remark: '',
              isLeaf: 0,
              parentId: '02',
              isShow: 0,
              itemName: '研发设计',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/02/0201',
              isProduct: 0,
              id: '0201',
              levelOrder: 0
            },
            {
              pathName: '/机械装备行业/智能制造',
              itemCode: '0202',
              icon: 'anzhuang',
              remark: '',
              isLeaf: 0,
              parentId: '02',
              isShow: 0,
              itemName: '智能制造',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/02/0202',
              isProduct: 0,
              id: '0202',
              levelOrder: 1
            },
            {
              pathName: '/机械装备行业/仓储',
              itemCode: '0203',
              icon: 'chanpin',
              remark: '',
              isLeaf: 0,
              parentId: '02',
              isShow: 0,
              itemName: '仓储',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/02/0203',
              isProduct: 0,
              id: '0203',
              levelOrder: 2
            },
            {
              pathName: '/机械装备行业/物流',
              itemCode: '0204',
              icon: 'shehui',
              remark: '',
              isLeaf: 0,
              parentId: '02',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/02/0204',
              isProduct: 0,
              id: '0204',
              levelOrder: 3
            },
            {
              pathName: '/机械装备行业/远程运维',
              itemCode: '0205',
              icon: 'shuju',
              remark: '',
              isLeaf: 0,
              parentId: '02',
              isShow: 0,
              itemName: '远程运维',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/02/0205',
              isProduct: 0,
              id: '0205',
              levelOrder: 4
            },
            {
              pathName: '/机械装备行业/金融服务',
              itemCode: '0206',
              icon: 'xiaoshou',
              remark: '',
              isLeaf: 0,
              parentId: '02',
              isShow: 0,
              itemName: '金融服务',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/02/0206',
              isProduct: 0,
              id: '0206',
              levelOrder: 5
            }
          ],
          levelId: 1,
          pathCode: '/02',
          isProduct: 0,
          id: '02',
          levelOrder: 2
        },
        {
          pathName: '/汽车行业',
          itemCode: '03',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '汽车行业',
          route: '',
          children: [
            {
              pathName: '/汽车行业/智能制造',
              itemCode: '0301',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '03',
              isShow: 0,
              itemName: '智能制造',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/03/0301',
              isProduct: 0,
              id: '0301',
              levelOrder: 1
            },
            {
              pathName: '/汽车行业/售后',
              itemCode: '0302',
              icon: 'shouhou',
              remark: '',
              isLeaf: 0,
              parentId: '03',
              isShow: 0,
              itemName: '售后',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/03/0302',
              isProduct: 0,
              id: '0302',
              levelOrder: 2
            },
            {
              pathName: '/汽车行业/车队管理',
              itemCode: '0303',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '03',
              isShow: 0,
              itemName: '车队管理',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/03/0303',
              isProduct: 0,
              id: '0303',
              levelOrder: 3
            },
            {
              pathName: '/汽车行业/车辆远程管理',
              itemCode: '0304',
              icon: 'download',
              remark: '',
              isLeaf: 0,
              parentId: '03',
              isShow: 0,
              itemName: '车辆远程管理',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/03/0304',
              isProduct: 0,
              id: '0304',
              levelOrder: 4
            },
            {
              pathName: '/汽车行业/数据分析',
              itemCode: '0305',
              icon: 'tongji2',
              remark: '',
              isLeaf: 0,
              parentId: '03',
              isShow: 0,
              itemName: '数据分析',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/03/0305',
              isProduct: 0,
              id: '0305',
              levelOrder: 5
            },
            {
              pathName: '/汽车行业/金融服务',
              itemCode: '0306',
              icon: 'money',
              remark: '',
              isLeaf: 0,
              parentId: '03',
              isShow: 0,
              itemName: '金融服务',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/03/0306',
              isProduct: 0,
              id: '0306',
              levelOrder: 6
            }
          ],
          levelId: 1,
          pathCode: '/03',
          isProduct: 0,
          id: '03',
          levelOrder: 3
        },
        {
          pathName: '/厨电行业',
          itemCode: '04',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '厨电行业',
          route: '',
          children: [
            {
              pathName: '/厨电行业/设计研发',
              itemCode: '0401',
              icon: 'tiwen',
              remark: '',
              isLeaf: 0,
              parentId: '04',
              isShow: 0,
              itemName: '设计研发',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/04/0401',
              isProduct: 0,
              id: '0401',
              levelOrder: 1
            },
            {
              pathName: '/厨电行业/供应链',
              itemCode: '0402',
              icon: 'bianji',
              remark: '',
              isLeaf: 0,
              parentId: '04',
              isShow: 0,
              itemName: '供应链',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/04/0402',
              isProduct: 0,
              id: '0402',
              levelOrder: 2
            },
            {
              pathName: '/厨电行业/智能制造',
              itemCode: '0403',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '04',
              isShow: 0,
              itemName: '智能制造',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/04/0403',
              isProduct: 0,
              id: '0403',
              levelOrder: 3
            },
            {
              pathName: '/厨电行业/物流',
              itemCode: '0404',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '04',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/04/0404',
              isProduct: 0,
              id: '0404',
              levelOrder: 4
            },
            {
              pathName: '/厨电行业/售后',
              itemCode: '0405',
              icon: 'shouhou',
              remark: '',
              isLeaf: 0,
              parentId: '04',
              isShow: 0,
              itemName: '售后',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/04/0405',
              isProduct: 0,
              id: '0405',
              levelOrder: 5
            },
            {
              pathName: '/厨电行业/增值服务',
              itemCode: '0406',
              icon: 'xiaoshou',
              remark: '',
              isLeaf: 0,
              parentId: '04',
              isShow: 0,
              itemName: '增值服务',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/04/0406',
              isProduct: 0,
              id: '0406',
              levelOrder: 6
            }
          ],
          levelId: 1,
          pathCode: '/04',
          isProduct: 0,
          id: '04',
          levelOrder: 4
        },
        {
          pathName: '/纺织行业',
          itemCode: '05',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '纺织行业',
          route: '',
          children: [
            {
              pathName: '/纺织行业/引流',
              itemCode: '0501',
              icon: 'admin',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '引流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0501',
              isProduct: 0,
              id: '0501',
              levelOrder: 1
            },
            {
              pathName: '/纺织行业/订单管理',
              itemCode: '0502',
              icon: 'liebiao2',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '订单管理',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0502',
              isProduct: 0,
              id: '0502',
              levelOrder: 2
            },
            {
              pathName: '/纺织行业/个性化设计',
              itemCode: '0503',
              icon: 'tiwen',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '个性化设计',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0503',
              isProduct: 0,
              id: '0503',
              levelOrder: 3
            },
            {
              pathName: '/纺织行业/供应链管理',
              itemCode: '0504',
              icon: 'system',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '供应链管理',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0504',
              isProduct: 0,
              id: '0504',
              levelOrder: 4
            },
            {
              pathName: '/纺织行业/智能排产',
              itemCode: '0505',
              icon: 'log',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '智能排产',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0505',
              isProduct: 0,
              id: '0505',
              levelOrder: 5
            },
            {
              pathName: '/纺织行业/智能生产',
              itemCode: '0506',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '智能生产',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0506',
              isProduct: 0,
              id: '0506',
              levelOrder: 6
            },
            {
              pathName: '/纺织行业/质量检验',
              itemCode: '0507',
              icon: 'needAll',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '质量检验',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0507',
              isProduct: 0,
              id: '0507',
              levelOrder: 7
            },
            {
              pathName: '/纺织行业/物流',
              itemCode: '0508',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0508',
              isProduct: 0,
              id: '0508',
              levelOrder: 8
            },
            {
              pathName: '/纺织行业/售后',
              itemCode: '0509',
              icon: 'suooprt',
              remark: '',
              isLeaf: 0,
              parentId: '05',
              isShow: 0,
              itemName: '售后',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/05/0509',
              isProduct: 0,
              id: '0509',
              levelOrder: 9
            }
          ],
          levelId: 1,
          pathCode: '/05',
          isProduct: 0,
          id: '05',
          levelOrder: 5
        },
        {
          pathName: '/包装行业',
          itemCode: '06',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '包装行业',
          route: '',
          children: [
            {
              pathName: '/包装行业/营销',
              itemCode: '0601',
              icon: 'chanpin',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '营销',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0601',
              isProduct: 0,
              id: '0601',
              levelOrder: 0
            },
            {
              pathName: '/包装行业/设计',
              itemCode: '0602',
              icon: 'tiwen',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '设计',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0602',
              isProduct: 0,
              id: '0602',
              levelOrder: 2
            },
            {
              pathName: '/包装行业/定制',
              itemCode: '0603',
              icon: 'sheji',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '定制',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0603',
              isProduct: 0,
              id: '0603',
              levelOrder: 3
            },
            {
              pathName: '/包装行业/拆单',
              itemCode: '0604',
              icon: 'anzhuang',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '拆单',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0604',
              isProduct: 0,
              id: '0604',
              levelOrder: 4
            },
            {
              pathName: '/包装行业/生产',
              itemCode: '0605',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '生产',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0605',
              isProduct: 0,
              id: '0605',
              levelOrder: 5
            },
            {
              pathName: '/包装行业/物流',
              itemCode: '0606',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0606',
              isProduct: 0,
              id: '0606',
              levelOrder: 6
            },
            {
              pathName: '/包装行业/售后',
              itemCode: '0607',
              icon: 'suooprt',
              remark: '',
              isLeaf: 0,
              parentId: '06',
              isShow: 0,
              itemName: '售后',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/06/0607',
              isProduct: 0,
              id: '0607',
              levelOrder: 7
            }
          ],
          levelId: 1,
          pathCode: '/06',
          isProduct: 0,
          id: '06',
          levelOrder: 6
        },
        {
          pathName: '/家具行业',
          itemCode: '07',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '家具行业',
          route: '',
          children: [
            {
              pathName: '/家具行业/售前',
              itemCode: '0701',
              icon: 'shouqian',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '售前',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0701',
              isProduct: 0,
              id: '0701',
              levelOrder: 0
            },
            {
              pathName: '/家具行业/设计',
              itemCode: '0702',
              icon: 'sheji',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '设计',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0702',
              isProduct: 0,
              id: '0702',
              levelOrder: 1
            },
            {
              pathName: '/家具行业/订单',
              itemCode: '0703',
              icon: 'dingdan',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '订单',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0703',
              isProduct: 0,
              id: '0703',
              levelOrder: 2
            },
            {
              pathName: '/家具行业/生产',
              itemCode: '0704',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '生产',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0704',
              isProduct: 0,
              id: '0704',
              levelOrder: 3
            },
            {
              pathName: '/家具行业/物流',
              itemCode: '0705',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0705',
              isProduct: 0,
              id: '0705',
              levelOrder: 4
            },
            {
              pathName: '/家具行业/安装',
              itemCode: '0706',
              icon: 'anzhuang',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '安装',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0706',
              isProduct: 0,
              id: '0706',
              levelOrder: 5
            },
            {
              pathName: '/家具行业/售后',
              itemCode: '0707',
              icon: 'shouhou',
              remark: '',
              isLeaf: 0,
              parentId: '07',
              isShow: 0,
              itemName: '售后',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/07/0707',
              isProduct: 0,
              id: '0707',
              levelOrder: 6
            }
          ],
          levelId: 1,
          pathCode: '/07',
          isProduct: 0,
          id: '07',
          levelOrder: 7
        },
        {
          pathName: '/生鲜食品行业',
          itemCode: '08',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '生鲜食品行业',
          route: '',
          children: [
            {
              pathName: '/生鲜食品行业/生产',
              itemCode: '0801',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '生产',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0801',
              isProduct: 0,
              id: '0801',
              levelOrder: 1
            },
            {
              pathName: '/生鲜食品行业/加工',
              itemCode: '0802',
              icon: 'shezhi',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '加工',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0802',
              isProduct: 0,
              id: '0802',
              levelOrder: 2
            },
            {
              pathName: '/生鲜食品行业/销售',
              itemCode: '0803',
              icon: 'defuser',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '销售',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0803',
              isProduct: 0,
              id: '0803',
              levelOrder: 3
            },
            {
              pathName: '/生鲜食品行业/仓储',
              itemCode: '0804',
              icon: 'shouye',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '仓储',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0804',
              isProduct: 0,
              id: '0804',
              levelOrder: 4
            },
            {
              pathName: '/生鲜食品行业/物流',
              itemCode: '0805',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0805',
              isProduct: 0,
              id: '0805',
              levelOrder: 5
            },
            {
              pathName: '/生鲜食品行业/溯源',
              itemCode: '0806',
              icon: 'daohang',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '溯源',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0806',
              isProduct: 0,
              id: '0806',
              levelOrder: 6
            },
            {
              pathName: '/生鲜食品行业/金融服务',
              itemCode: '0807',
              icon: 'money',
              remark: '',
              isLeaf: 0,
              parentId: '08',
              isShow: 0,
              itemName: '金融服务',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/08/0807',
              isProduct: 0,
              id: '0807',
              levelOrder: 7
            }
          ],
          levelId: 1,
          pathCode: '/08',
          isProduct: 0,
          id: '08',
          levelOrder: 8
        },
        {
          pathName: '/电梯行业',
          itemCode: '09',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '电梯行业',
          route: '',
          children: [
            {
              pathName: '/电梯行业/研发设计',
              itemCode: '0901',
              icon: 'tiwen',
              remark: '',
              isLeaf: 0,
              parentId: '09',
              isShow: 0,
              itemName: '研发设计',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/09/0901',
              isProduct: 0,
              id: '0901',
              levelOrder: 1
            },
            {
              pathName: '/电梯行业/智能制造',
              itemCode: '0902',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '09',
              isShow: 0,
              itemName: '智能制造',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/09/0902',
              isProduct: 0,
              id: '0902',
              levelOrder: 2
            },
            {
              pathName: '/电梯行业/仓储',
              itemCode: '0903',
              icon: 'shouye',
              remark: '',
              isLeaf: 0,
              parentId: '09',
              isShow: 0,
              itemName: '仓储',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/09/0903',
              isProduct: 0,
              id: '0903',
              levelOrder: 3
            },
            {
              pathName: '/电梯行业/物流',
              itemCode: '0904',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '09',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/09/0904',
              isProduct: 0,
              id: '0904',
              levelOrder: 4
            },
            {
              pathName: '/电梯行业/远程运维',
              itemCode: '0905',
              icon: 'shuju',
              remark: '',
              isLeaf: 0,
              parentId: '09',
              isShow: 0,
              itemName: '远程运维',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/09/0905',
              isProduct: 0,
              id: '0905',
              levelOrder: 5
            },
            {
              pathName: '/电梯行业/保养服务',
              itemCode: '0906',
              icon: 'zhiliang',
              remark: '',
              isLeaf: 0,
              parentId: '09',
              isShow: 0,
              itemName: '保养服务',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/09/0906',
              isProduct: 0,
              id: '0906',
              levelOrder: 6
            }
          ],
          levelId: 1,
          pathCode: '/09',
          isProduct: 0,
          id: '09',
          levelOrder: 9
        },
        {
          pathName: '/精密模具行业',
          itemCode: '10',
          icon: '',
          remark: '',
          isLeaf: 0,
          parentId: '00',
          isShow: 0,
          itemName: '精密模具行业',
          route: '',
          children: [
            {
              pathName: '/精密模具行业/营销',
              itemCode: '1001',
              icon: 'defuser',
              remark: '',
              isLeaf: 0,
              parentId: '10',
              isShow: 0,
              itemName: '营销',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/10/1001',
              isProduct: 0,
              id: '1001',
              levelOrder: 1
            },
            {
              pathName: '/精密模具行业/设计',
              itemCode: '1002',
              icon: 'sheji',
              remark: '',
              isLeaf: 0,
              parentId: '10',
              isShow: 0,
              itemName: '设计',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/10/1002',
              isProduct: 0,
              id: '1002',
              levelOrder: 2
            },
            {
              pathName: '/精密模具行业/智能制造',
              itemCode: '1003',
              icon: 'shengchan',
              remark: '',
              isLeaf: 0,
              parentId: '10',
              isShow: 0,
              itemName: '智能制造',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/10/1003',
              isProduct: 0,
              id: '1003',
              levelOrder: 3
            },
            {
              pathName: '/精密模具行业/物流',
              itemCode: '1004',
              icon: 'wuliu',
              remark: '',
              isLeaf: 0,
              parentId: '10',
              isShow: 0,
              itemName: '物流',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/10/1004',
              isProduct: 0,
              id: '1004',
              levelOrder: 4
            },
            {
              pathName: '/精密模具行业/售后',
              itemCode: '1005',
              icon: 'shouhou',
              remark: '',
              isLeaf: 0,
              parentId: '10',
              isShow: 0,
              itemName: '售后',
              route: '',
              children: [],
              levelId: 2,
              pathCode: '/10/1005',
              isProduct: 0,
              id: '1005',
              levelOrder: 5
            }
          ],
          levelId: 1,
          pathCode: '/10',
          isProduct: 0,
          id: '10',
          levelOrder: 10
        }
      ]
      this.maxLevel = 1
      this.handleExpandOption()
      this.visible = true
      // this.$http({
      //     url: this.$http.adornUrl(this.$api.PUB.MENU),
      //     method: 'get'
      // }).then(({data}) => {
      //     if (data && data.code === 0) {
      //         this.serviceItemList = data.menu.menu
      //         // this.maxLevel = data.menu.maxLevel || 1
      //         this.maxLevel = 1
      //         // localStorage.setItem('serviceItemMenu', JSON.stringify(data.menu))
      //         this.handleExpandOption()
      //     }
      //     this.visible = true
      // }).catch(() => {
      //     this.visible = true
      // })
    },
    // 处理服务类别条件栏
    handleExpandOption() {
      for (let i = 0; i < this.maxLevel; i++) {
        this.expandOption['expand' + (i + 1)] = false
        this.serviceItemChoose['itemChoose' + (i + 1)] = {
          itemCode: 'all',
          itemName: '全部'
        }
        this.itemDataList['dataList' + (i + 1)] = []
      }
      this.handleItem()
    },
    handleItem() {
      let itemCode = this.dataForm.itemCode
      // 处理每层选择的itemCode
      for (let i = 1; i <= this.maxLevel; i++) {
        if (!checkIsNull(itemCode) && itemCode.length >= this.indexLen[i].len) {
          let thisItemCode = itemCode.substring(0, this.indexLen[i].len)
          if (i === 1) {
            this.serviceItemChoose[
              'itemChoose' + i
            ] = this.serviceItemList.filter(
              item => item.itemCode === thisItemCode
            )[0]
          } else {
            this.serviceItemChoose['itemChoose' + i] = this.serviceItemChoose[
              'itemChoose' + (i - 1)
            ].children.filter(item => item.itemCode === thisItemCode)[0]
          }
        } else {
          this.serviceItemChoose['itemChoose' + i] = {
            itemCode: 'all',
            itemName: '全部'
          }
        }
      }
      // 处理每层的数据
      for (let i = 1; i <= this.maxLevel; i++) {
        if (i === 1) {
          this.itemDataList['dataList' + i] = this.$deepClone(
            this.serviceItemList
          )
        } else {
          if (
            this.serviceItemChoose['itemChoose' + (i - 1)].itemCode !== 'all'
          ) {
            this.itemDataList['dataList' + i] = this.serviceItemChoose[
              'itemChoose' + (i - 1)
            ].children
          } else {
            this.itemDataList['dataList' + i] = []
          }
        }
      }
      this.$forceUpdate()
    },
    // 是否展开所有条件
    switchExpand(index) {
      this.expandOption['expand' + index] = !this.expandOption[
        'expand' + index
      ]
      this.$forceUpdate()
    },
    // 选择服务类别监听
    itemClickListener(index, item) {
      // 选择的全部
      if (index === 1 && item.itemCode === 'all') {
        this.dataForm.itemCode = ''
      } else if (item.itemCode === 'all') {
        this.dataForm.itemCode = this.serviceItemChoose[
          'itemChoose' + (index - 1)
        ].itemCode
      } else {
        if (item.itemCode === '800101') {
          // window.open('http://model.smartquality.cn/')
          return
        }
        this.dataForm.itemCode = item.itemCode
      }
      this.handleItem()
      this.searchData()
    },
    // 获取产品列表
    getDataList(refresh) {
      if (refresh) {
        this.pageIndex = 1
      }
      this.loading = true
      // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
      this.$http({
        url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
        methods: 'get',
        params: this.$http.adornParams({
          // 'page': this.pageIndex,
          // 'limit': this.pageSize,
          // 'serviceName': this.dataForm.serviceName || null,
          // 'itemCode': this.dataForm.itemCode || null ,
          // 'orgCode': this.dataForm.orgCode || null,
          // 'serviceMethod': this.dataForm.serviceMethod === 0 ? null : this.dataForm.serviceMethod,
          // 'sortType': this.dataForm.sortType || null,
          // 'priceLow': this.dataForm.priceLow || null,
          // 'priceHigh': this.dataForm.priceHigh || null
          startPage: this.pageIndex - 1,
          pageSize: this.pageSize,
          keywords: this.dataForm.serviceName || null,
          itemCode: this.dataForm.itemCode || null,
          serviceMethod: this.dataForm.serviceMethod,
          sortType: this.dataForm.sortType,
          priceLow: this.dataForm.priceLow,
          priceHigh: this.dataForm.priceHigh,
          // 服务商ID，在另一个项目跳转时使用
          providerId: this.$route.query.providerId || null,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
          // this.dataList = data.page.list
          // this.totalCount = data.page.totalCount
          // this.totalPage = data.page.totalPage
          this.dataList = data.result.list || []
          this.totalCount = data.result.total || 0
          this.totalPage = Math.ceil(data.result.total / this.pageSize) || 1
        } else {
          this.dataList = []
          this.totalCount = 0
          this.totalPage = 1
        }
        this.loading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList(true)
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList(false)
    },
    chooseMethod(dictId) {
      this.dataForm.serviceMethod = dictId
      this.searchData()
    },
    searchData() {
      this.$nextTick(() => {
        this.getDataList(true)
      })
    },
    // 选择产品
    chooseProduct(item) {
      let itemCodeType = 'search'
      const routeName = this.$route.query.providerId? 'service-personal' :'service'
      // this.keepAliveArr = ['search']
      this.$router.push({
        name: routeName,
        query: { id: item.id, itemCodeType: itemCodeType }
      })
    },
    //控制字数
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 12) {
        return value.slice(0, 12) + '...'
      }
      return value
    }
  }
}
</script>

<style scoped>
.search-box {
  width: 1330px;
  margin: auto;
  padding: 24px;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #ebeef5;
}

.search-line {
  display: flex;
  width: 100%;
  /*line-height: 2.4rem;*/
  margin-bottom: 16px;
  border-bottom: 1px solid #f2f6fc;
  padding-bottom: 11px;
}

.search-line-label {
  display: flex;
  margin-right: 10px;
  text-align: right;
}

.search-line-label span {
  display: inline-block;
  height: 32px;
  line-height: 32px;
}

.search-line-label span:after {
  content: "：";
}

.search-line .search-line-content {
  flex: 1 1;
  width: 100%;
}

.search-line-content-box {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.content-box-select {
  padding-right: 50px;
  position: relative;
  max-height: 32px;
  margin-left: -8px;
  overflow: hidden;
  line-height: 32px;
  transition: all 0.3s;
}

.content-box-select-expand {
  max-height: 200px;
  transition: all 0.3s;
}

.content-box-select-item {
  box-sizing: border-box;
  display: inline-block;
  margin-right: 16px;
  padding: 0 8px;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.content-box-select-item:hover {
  color: #ff9123;
}

.content-box-select .on {
  border-radius: 3px;
  color: #fff;
  background: #ff9123;
}

.search-price >>> .el-input__inner {
  border-radius: 0;
  padding: 0 5px;
}

.search-price >>> .el-button--mini {
  padding: 7px 10px;
}
</style>
