<template>
  <div class="pr">
    <!--        <div class="trades uf uf-pj">-->
    <!--            <div class="trade-li uf uf-ver uf-ac" v-for="(item, index) in trades" :key="index"-->
    <!--                 :class="{on: index === curTradeIndex}" @click="tradesClick(index)">-->
    <!--                <div class="icon-box uf uf-ac uf-pc" :style="{background: item.color, 'border-color': item.color2}">-->
    <!--                    <i :class="`iconfont ${item.icon}`"></i>-->
    <!--                </div>-->
    <!--                <div class="name mt-3 fwb f16">{{ item.name }}</div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="banner-box">
      <div class="uf" style="margin-top: -90px">
        <div class="banner uf-f1 pr header-container self_bg">
          <div class="header-wrap">
            <div class="mt-1">
              <el-card :bodyStyle="bodyStyle">
                <div class="header-container">
                  <!--轮播图 begin-->
                  <div style="width: 100vw;height:700px">
                    <el-carousel :interval="5000" arrow="always" ref="carousel" class="wi100 hi100">
                      <el-carousel-item v-for="(item, index) in bannerLocal" :key="index"
                                        name="index">
                        <el-image class="wi100 hi100" :src="item" fit="cover"></el-image>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <!--轮播图 end-->
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box mt-4 pt-2">
      <div class="any-box uf uf-ac uf-pj">
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="viewNum"/>
          <div class="mt-2 f16 fwb">访问量</div>
        </div>
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="serviceNum"/>
          <div class="mt-2 f16 fwb">服务项总数</div>
        </div>
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="orderNum"/>
          <div class="mt-2 f16 fwb">订单成交量</div>
        </div>
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="userNum"/>
          <div class="mt-2 f16 fwb">注册用户数</div>
        </div>
      </div>
      <div>
        <div class="title-wrap uf uf-pc">
          <div class="title-icon"></div>
          <div class="title-name">三大专题</div>
          <div class="title-icon"></div>
        </div>
        <div class="box1 uf uf-pj">
          <div class="b-list tac" @click="handlerRoute('data-support')">
            <i class="iconfont icon--shuju"></i>
            <div class="fwb txt">组件及方案服务</div>
            <div class="op-08 dec">
              通过对各个行业的网络驱动设计，提供全流程、全周期的组件及解决方案服务。
            </div>
          </div>
          <div class="b-list tac">
            <i class="iconfont icon-hulianwangyingxiao-2"></i>
            <div class="fwb txt">共性服务支撑</div>
            <div class="op-08 dec">
              通过对原始数据的采集、清洗和处理，建立数据模型，为各行业提供有力的数据支撑，实现行业数字化、智能化。
            </div>
          </div>
          <div class="b-list tac" @click="handlerRoute('data-trading')">
            <i class="iconfont icon-hulianwangyingxiao-1"></i>
            <div class="fwb txt">数据安全与交易</div>
            <div class="op-08 dec">
              融合行业平台，链接行业上下游，同时行业实现头部企业与中小企业的匹配，打通原材料、生产、销售各个环节的数据共享。
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 pt-4">
        <div class="title-wrap uf uf-pc pr">
          <div class="title-icon"></div>
          <div class="title-name">新闻公告</div>
          <div class="title-icon"></div>
          <div class="cp more" @click="$router.push({name: 'news'})">查看全部<i class="el-icon-arrow-right ml-1"></i>
          </div>
        </div>
        <div class="box2 uf uf-pj mt-3">
          <div class="left">
            <div class="bg-color">
              <div class="tac">
                <span class="f30 fwb">新闻公告</span>
                <i class="en f22 fwb">Latast News</i>
              </div>
              <div class="cp mt-3 more" @click="$router.push({name: 'news'})">查看全部<i
                  class="el-icon-arrow-right ml-1"></i></div>
            </div>
          </div>
          <div class="right uf-f1">
            <div class="n-list uf uf-ac" v-for="item in newsList" :key="item.id" @click="showNewsDetail(item)">
              <div class="uf-f1 uf uf-ac">
                <span class="c-base f20 mr-2">●</span>
                <span class="toe uf-f1">{{ item.newsName || item.noticeName }}</span>
              </div>
              <div class="op-06 ml-4">{{ item.updateTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 pt-4">
        <div class="title-wrap uf uf-pc pr">
          <div class="title-icon"></div>
          <div class="title-name">特色服务</div>
          <div class="title-icon"></div>
          <div class="cp more" @click="$router.push({name: 'search'})">查看全部<i
              class="el-icon-arrow-right ml-1"></i></div>
        </div>
        <div class="box2 uf uf-pj mt-3">
          <div class="left service">
            <div class="bg-color">
              <div class="tac">
                <span class="f30 fwb">特色服务</span>
                <i class="en f22 fwb">Characteristic Service</i>
              </div>
              <div class="cp mt-3 more" @click="$router.push({name: 'search'})">查看全部<i class="el-icon-arrow-right ml-1"></i></div>
            </div>
          </div>
          <div class="right uf uf-pj uf-ac uf-f1 fwrap-w">
            <div class="s-list uf uf-ac cp" v-for="item in serviceList" :key="item.id" @click="toService(item.id)">
              <el-image :src="$cons.SERVER_PATH + item.logoUrl" class="uf-f1"
                        style="height: 100px;border-radius: 10px;overflow: hidden" fit="fill">
                <div slot="error">
                  <img :src="item.img" alt="" width="100%" height="100px"/>
                  <!--                                    <div class="item-error">-->
                  <!--                                        <img :src="item.img" alt="" width="100%">-->
                  <!--&lt;!&ndash;                                        <div>{{ item.serviceName }}</div>&ndash;&gt;-->
                  <!--                                    </div>-->
                </div>
              </el-image>
              <div class="ml-2 wi60">
                <div class="fwb">{{ item.serviceName }}</div>
                <div class="op-08 mt-1 f12">行业：{{ item.itemName }}</div>
                <div class="op-08 mt-1 f12">公司：{{ item.enterpriseName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 pt-4">
        <div class="title-wrap uf uf-pc pr">
          <div class="title-icon"></div>
          <div class="title-name">需求大厅</div>
          <div class="title-icon"></div>
          <div class="cp more" @click="$router.push({name: 'demand-hall'})">查看全部<i
              class="el-icon-arrow-right ml-1"></i></div>
        </div>
        <div class="box2 uf uf-pj mt-3">
          <div class="x-list uf uf-ver uf-pc uf-ac" v-for="(item, index) in needList" :key="index">
            <el-image :src="item.img" style="width: 240px;height: 175px" fit="cover"></el-image>
            <div class="fwb f16 mt-2 toe tac">{{ item.needName }}</div>
            <div class="mt-3">
              <zx-tag v-if="item.status === 'dgj'" color="#ff1f1f"> ● {{ needDictMap[item.status] }}</zx-tag>
              <zx-tag v-else-if="item.status === 'dyhqr'" color="#ff6a00">● {{ needDictMap[item.status] }}</zx-tag>
              <zx-tag v-else-if="item.status === 'ywc'" color="#009DFF"> ● {{ needDictMap[item.status] }}</zx-tag>
              <zx-tag v-else-if="item.status === 'ycd'" color="#00A139"> ● {{ needDictMap[item.status] }}</zx-tag>
              <zx-tag v-else color="#757575"> ● {{ needDictMap[item.status] }}</zx-tag>
            </div>
            <div class="mt-2 op-07 f14">所属行业：{{ item.itemName }}</div>
            <!--                        <div class="mt-3">-->
            <!--                            <el-button type="primary">立即对接</el-button>-->
            <!--                        </div>-->
          </div>
        </div>
      </div>
    </div>
    <main-tools></main-tools>
    <news-detail v-if="newsDetailVisible" ref="newsDetail"></news-detail>
  </div>
</template>

<script>
import MainTools from './main-tools'
import CountTo from 'vue-count-to'
import NewsDetail from '@/views/news/detail-dialog.vue'
import ZxTag from '@/components/zx-tag/zx-tag.vue'
// import ServiceCard from '../components/service-card'

export default {
  name: 'self-service',
  components: {
    ZxTag,
    MainTools,
    CountTo,
    NewsDetail,
    // ServiceCard
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'login') {
      next(vm => {
        if (vm.unRead > 0) {
          vm.$notify.info({
            title: '消息提醒',
            message: '你有' + vm.unRead + '条未读消息',
            offset: 100
          })
        }
      })
    } else {
      next()
    }
  },
  data() {
    return {
      newsDetailVisible: false,
      serviceMethodDict: [
        {
          "orderNo": 1,
          "groupId": "SERVICE_LABEL",
          "dictName": "组件服务",
          "remark": "服务类型",
          "dictId": "API"
        },
        {
          "orderNo": 2,
          "groupId": "SERVICE_LABEL",
          "dictName": "方案服务",
          "remark": "方案服务",
          "dictId": "RECORD"
        },
        {
          "orderNo": 3,
          "groupId": "SERVICE_LABEL",
          "dictName": "咨询服务",
          "remark": "服务类型",
          "dictId": "ASK"
        },
        {
          "orderNo": 4,
          "groupId": "SERVICE_LABEL",
          "dictName": "一般服务",
          "remark": "服务类型",
          "dictId": "COMMON"
        },
        {
          "orderNo": 5,
          "groupId": "SERVICE_LABEL",
          "dictName": "订单",
          "remark": "服务类型",
          "dictId": "ORDER"
        }
      ],
      serviceMethodDictMap: {
        "ORDER": "订单",
        "RECORD": "方案服务",
        "ASK": "咨询服务",
        "COMMON": "一般服务",
        "API": "组件服务"
      },
      needDictMap: {
        "ywc": "已完成",
        "ygb": "已关闭",
        "dgj": "待跟进",
        "ycd": "已成单",
        "dyhqr": "待用户确认"
      },
      bannerLocal: [
        require('../../assets/img/banner1.jpg'),
        require('../../assets/img/banner2.jpg'),
        require('../../assets/img/banner3.jpg')
      ],
      xqList: [
        {name: '汽车物流运输提质增效', url: require('../../assets/img/index/img_3.jpg')},
        {name: '寻求马铃薯自动脱皮技术', url: require('../../assets/img/index/img_4.jpg')},
        {name: '光伏组件供应', url: require('../../assets/img/index/img_5.jpg')},
        {name: '寻求基于智能化、信息化的印刷..', url: require('../../assets/img/index/img_6.jpg')}
      ],
      curTradeIndex: 0,
      trades: [
        {name: '生物医药行业', icon: 'icon-shengwuyiyao', color: '#409EFF', color2: '#9FDCFF'},
        {name: '机械装备行业', icon: 'icon-jixie', color: '#ff6900', color2: '#ffbd73'},
        {name: '汽车行业', icon: 'icon-qiche', color: '#008638', color2: '#9cffd2'},
        {name: '厨电行业', icon: 'icon-youyanji', color: '#9200e5', color2: '#d19fff'},
        {name: '纺织行业', icon: 'icon-duanxiujikouyifu', color: '#c5a500', color2: '#c9c579'},
        {name: '包装行业', icon: 'icon-wuliaobaozhuangdan', color: '#409EFF', color2: '#9FDCFF'},
        {name: '家具行业', icon: 'icon-shafa', color: '#ff6900', color2: '#ffbd73'},
        {name: '生鲜食品行业', icon: 'icon-shuiguo', color: '#008638', color2: '#9cffd2'},
        {name: '电梯行业', icon: 'icon-dianti-xiantiao', color: '#9200e5', color2: '#d19fff'},
        {name: '精密模具行业', icon: 'icon-yewumoban', color: '#409EFF', color2: '#9FDCFF'}
      ],
      serviceNum: 785,
      viewNum: 875201,
      orderNum: 12413,
      userNum: 9870,
      sImg: require('../../assets/img/support1.png'),
      bannerList: [],
      fromLogin: false,
      config: {
        data: [
          '12313131',
          '3231kldksfks'
        ]
      },
      bodyStyle: {
        padding: '0',
        height: '700px'
      },
      itemBodyStyle: {
        padding: '0',
        height: '120px'
      },
      newsBodyStyle: {
        padding: '0',
        height: '210px'
      },
      newsList: [],
      serviceList: [],
      needList: [],
      classOption: {
        step: 1,
        hoverStop: true,
        direction: 1,
        waitTime: 1000
      }
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.user.name
      },
    },
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      },
      set(val) {
        this.$store.commit('user/updateLoginStatus', val)
      }
    },
    unRead: {
      get() {
        return this.$store.state.user.unRead
      }
    },
    enterpriseList: {
      get() {
        return this.$store.state.user.info
      },
      // set (val) { this.$store.commit('user/updateInfo', val) }
    },
    enterpriseId() {
      return this.enterpriseList[0] ? this.enterpriseList[0].id : ''
    }
  },
  watch: {
    // enterpriseId(val) {
    //     this.initNews(val)
    // }
  },
  created() {
    this.getCarousel()
    this.getNews()
    this.getServiceList()
    this.getNeedList()
    // if (this.loginStatus) {
    //     this.initNews(this.enterpriseId)
    // }
    // this.$getDictList('SERVICE_LABEL,NEED_STATUS', (dict) => {
    //   this.serviceMethodDict = dict.SERVICE_LABEL
    // })
    // this.$getDictMap('SERVICE_LABEL,NEED_STATUS', dict => {
    //   this.serviceMethodDictMap = dict['SERVICE_LABEL']
    //   this.needDictMap = dict['NEED_STATUS']
    // })
    this.initCommonParamNum()
  },
  methods: {
    // 公共数量
    initCommonParamNum() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.SEARCH.COMMON),
        method: 'GET',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.serviceNum = data.data.serviceNum
          this.viewNum = data.data.viewNum
          this.orderNum = data.data.orderNum
          this.userNum = data.data.userNum
          if (data.data.serviceInfoList) {
            this.components[0].name = data.data.serviceInfoList[0].serviceName
            this.components[0].id = data.data.serviceInfoList[0].id
            this.components[0].company = data.data.serviceInfoList[0].enterpriseName
            this.components[1].name = data.data.serviceInfoList[0].serviceName
            this.components[1].id = data.data.serviceInfoList[0].id
            this.components[1].company = data.data.serviceInfoList[0].enterpriseName
            this.components[2].name = data.data.serviceInfoList[0].serviceName
            this.components[2].id = data.data.serviceInfoList[0].id
            this.components[2].company = data.data.serviceInfoList[0].enterpriseName
            this.components[3].name = data.data.serviceInfoList[0].serviceName
            this.components[3].id = data.data.serviceInfoList[0].id
            this.components[3].company = data.data.serviceInfoList[0].enterpriseName
            this.components[4].name = data.data.serviceInfoList[0].serviceName
            this.components[4].id = data.data.serviceInfoList[0].id
            this.components[4].company = data.data.serviceInfoList[0].enterpriseName
            this.components[5].name = data.data.serviceInfoList[0].serviceName
            this.components[5].id = data.data.serviceInfoList[0].id
            this.components[5].company = data.data.serviceInfoList[0].enterpriseName
          }
        }
      })
    },
    showNewsDetail(item) {
      this.newsDetailVisible = true
      this.$nextTick(() => {
        this.$refs['newsDetail'].init(item)
      })
      // this.
      // let itemCodeType = 'search'
      // this.keepAliveArr = ['search']
      // this.$router.push({name: 'service', query: {id: item.id,itemCodeType:itemCodeType}})
    },
    toService(id) {
      this.$router.push({name: 'service', query: {id: id}})
    },
    tradesClick(index) {
      this.curTradeIndex = index
      this.$router.push({name: 'trades-detail', query: {trade: index}})
    },
    // 获取特色服务
    getServiceList() {
      // this.loading = true
      // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
      this.$http({
        url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
        methods: 'get',
        params: this.$http.adornParams({
          'startPage': 0,
          'pageSize': 6
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
          // this.dataList = data.page.list
          // this.totalCount = data.page.totalCount
          // this.totalPage = data.page.totalPage
          // this.serviceList = data.result.list || []
          this.serviceList = this.handleTradeImg(data.result.list || [], 4)
        }
        this.loading = false
      })
    },
    // 获取需求大厅
    getNeedList() {
      // this.loading = true
      // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
      this.$http({
        url: this.$http.adornUrl(this.$api.NEED.NEEDLIST),
        methods: 'get',
        params: this.$http.adornParams({
          'startPage': 0,
          'pageSize': 4
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
          // this.dataList = data.page.list
          // this.totalCount = data.page.totalCount
          // this.totalPage = data.page.totalPage
          // let arr = data.result.list || []
          //

          this.needList = this.handleTradeImg(data.result.list || [], 4)
          // let
          // let n = {}
          // arr.forEach((item, index) => {
          //     n[`i${item.itemCode}`] = 0
          // })
          // console.log(n)
          // arr.forEach((item, index) => {
          //     n[`i${item.itemCode}`] ++
          //     item.img = require (`@/assets/img/trades/${item.itemCode}/img_${n[`i${item.itemCode}`]}.jpg`)
          //     console.log(arr)
          // })
          // this.needList = arr
        }
        this.loading = false
      })
    },
    handleTradeImg(list, n) {
      if (list.length < 1) return []
      let arr = list
      let t = {}
      arr.forEach((item, index) => {
        t[`i${item.itemCode.slice(0, 2)}`] = 0
      })
      arr.forEach((item) => {
        t[`i${item.itemCode.slice(0, 2)}`]++
        let num = t[`i${item.itemCode.slice(0, 2)}`] % n
        if (num === 0) num = n
        item.img = require(`@/assets/img/trades/${item.itemCode.slice(0, 2)}/img_${num}.jpg`)
      })
      return arr
    },
    getNews() {
      this.$http({
        url: this.$http.adornUrl(this.$api.NEWS.LIST),
        method: 'get',
        params: this.$http.adornParams({
          'startPage': 0,
          'pageSize': 10,
          'newsStatus': 1,
          'noticeStatus': 1
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let listNews = data.page.list
          this.$http({
            url: this.$http.adornUrl(this.$api.NOTICE.LIST),
            method: 'get',
            params: this.$http.adornParams({
              'startPage': 0,
              'pageSize': 10,
              'newsStatus': 1,
              'noticeStatus': 1
            })
          }).then(res => {
            if (res.data && res.data.code === 0) {
              let listNotice = res.data.page.list
              let list = [...listNews, ...listNotice]
              list.sort((a, b) => {
                return new Date(b.updateTime) - new Date(a.updateTime)
              })
              this.newsList = list
            }
          })
          // list.forEach( v => {
          //     v.cover = this.getFirstImg(this.curNewsTabIndex === 0 ? v.newsObj : v.noticeObj)
          // })
          // console.log(list)
          // this.dataList = list
        }
      })
    },
    /**
     * 查询轮播图
     */
    getCarousel() {
      // this.$http({
      //   url: this.$http.adornUrl(this.$api.PUB.CAROUSEL),
      //   method: 'get',
      //   params: this.$http.adornParams()
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.bannerList = data.page.list
      //   }
      // })
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index)
    },
    toImplement(index) {
      if (index === 4) {
        this.$router.push({name: 'implement'})
      } else if (index === 5) {
        this.$router.push({name: 'search', params: {itemCode: '20'}})
      }
    },
    goPage(page = '') {
      this.$router.push({name: page})
    },
    handlerRoute(name) {
      if (name === 'data-trading') {
        // window.open(`${this.$cons.DATA_PATH}`, '_blank')
      } else if (name === 'data-support') {
        // window.open(`${this.$cons.COMPONENT_PATH}`, '_blank')
      }
      // this.$router.push({name})
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__arrow {
  width: 60px;
  height: 60px;
  font-size: 20px;
  background-color: rgba(31, 45, 61, .31);
}

::v-deep .el-carousel__arrow--right {
  right: 90px
}

::v-deep .el-carousel__arrow--left {
  left: 90px
}

.item-error {
  /*width: 245px;*/
  height: 100px;
  background-image: url("../../assets/img/product/default_3.png");
  background-size: cover;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 10px;
  color: #FFFFFF;
  overflow: hidden;
}

.trades {
  width: 1300px;
  margin: 20px auto 0;
  padding: 15px 20px;
  background: white;
  border-radius: 0 8px 8px;
  box-shadow: 0 0 20px rgba(0, 27, 42, 0.1);
  z-index: 99999;

  .trade-li {
    padding: 10px 0;
    width: 130px;
    margin: 0 4px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: all .3s ease;

    &:hover {
      background: #eeeeee;
    }

    .icon-box {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 4px solid;

      .iconfont {
        color: white;
        font-size: 28px;
      }
    }
  }

  .on, .on:hover {
    background: #e8e8e8;
    border-color: #ff9123;
  }
}

.title-icon {
  width: 30px;
  height: 76px;
  position: relative;
  transform: rotate(40deg);

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 76px;
    background: #ff9123;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 13px;
    width: 1px;
    height: 26px;
    background: #ff710d;
  }
}

.title-name {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 60px;
}

.content-box {
  width: 1300px;
  margin: 20px auto;

  .any-box {
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;

    .a-list {
      width: 30%;
      border-right: 1px solid #d0d0d0;

      &:last-child {
        border-right: none;
      }
    }
  }

  .title-wrap {
    .more {
      position: absolute;
      right: 0;
      top: 26px;
      color: #00b2ff;

      &:hover {
        color: #0089c7;
      }
    }
  }

  .box1 {
    margin-top: 20px;

    .b-list {
      width: 30%;
      padding: 30px 20px;
      border-radius: 10px;
      box-shadow: 0 0 30px rgba(0, 0, 0, .1);
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.06);
      }

      .iconfont {
        font-size: 80px;
        background-image: linear-gradient(60deg, #00b2ff, #00ffe9);
        background-clip: text;
        color: transparent;
      }

      .txt {
        margin-top: 20px;
        font-size: 24px;
      }

      .dec {
        font-size: 14px;
        margin-top: 15px;
      }
    }
  }

  .box2 {
    .left {
      width: 34%;
      height: 400px;
      border-radius: 8px 0 0 8px;
      background: url("../../assets/img/index/img_1.jpg");
      background-size: cover;

      .bg-color {
        padding: 50px 20px;
        color: white;
        margin-top: 90px;
        background: rgba(0, 0, 0, .1);
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        text-shadow: 0 0 8px rgba(0, 0, 0, .2);

        .en {
          line-height: 30px;
          border-bottom: 2px solid #fff;
          margin-left: 15px;
        }

        .more {
          float: right;
        }

        .more:hover {
          color: #5de1ff;
        }
      }
    }

    .service {
      background: url("../../assets/img/index/img_2.png");
    }

    .right {
      background: white;
      padding: 20px 30px;
      border-radius: 0 8px 8px 0;
      box-shadow: 0 0 30px rgba(0, 0, 0, .1);

      .n-list {
        line-height: 50px;
        border-bottom: 1px dashed #ababab;
        cursor: pointer;

        &:hover {
          color: #00b2ff;
        }
      }

      .s-list {
        width: 48%;
        border: 1px solid #eeeeee;
        padding: 10px;
        transition: all .3s ease;

        &:hover {
          background: #efefef;
        }
      }
    }

    .x-list {
      padding: 20px 0;
      width: 22%;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #00b2ff;
      box-shadow: 0 0 20px rgba(0, 0, 0, .1);
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.header-container {
  /*margin-top: 10px;*/
  /*padding-top: 10px;*/
  display: flex;
  justify-content: center;
  /*padding-bottom: 40px;*/
  /*background-color: #FFFFFF;*/
  /*width: 1400px;*/
  /*height: 600px;*/
}

.self_bg {
  background: url("../../assets/img/self/self_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.warp {
  height: 180px;
  width: 100%;
  overflow: hidden;
}

.header-wrap {
  display: flex;
  justify-content: center;
}


.banner-box {
  width: 100%;
  min-width: 1300px;
  /*background-image: url("../../assets/img/self/self_background.png");*/
}

.main .banner .bg-img {
  width: 100vw;
  min-width: 1300px;
  height: 685px;
  background-size: 1920px 685px;
  background-position: center top;
}

.banner-box .preview {
  position: absolute;
  bottom: -40px;
  //z-index: 11;
}

.banner-box .preview .wrap {
  width: 1000px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
}

.banner-box .mask {
  width: 100%;
  min-width: 1300px;
  height: 72px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.banner-box .mask span {
  width: 100%;
  min-width: 1200px;
  height: 72px;
  display: block;
  background: url("../../assets/img/mask-grey.png") no-repeat;
  background-size: 100% 72px;
  background-position: center top;
}


.banner-right {
  /*background-color: red;*/
  margin: .2rem;
  width: 300px;
  height: 602px;
  border-radius: 0px 8px 8px 0px;
  /*background-color: #4647BB;*/
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  /*justify-content: flex-end;*/
  /*background-color: #f0f0f0;*/
}

.ent-name {
  color: #409EFF;
}

.card-hover {
  /*padding: .1rem;*/
  height: 20%;
  cursor: pointer;
}


@media screen and (max-width: 1400px) {
  /*.banner-box >>> .el-carousel__arrow--left {*/
  /*  left: 22vw*/
  /*}*/
  /*.banner-box >>> .el-carousel__arrow--right {*/
  /*  right: 22vw*/
  /*}*/
}
</style>
